import { Alert, Slide, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { WS_TYPE } from "../constants/ws";
import {
  getAppSettings,
  getNotification,
  setWsReduxData,
} from "../lib/redux/wsSlice";

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />;
};

const SnackBarNotification = () => {
  const dispatch = useDispatch();

  const notification = useSelector((state) => getNotification(state));
  const appSettings = useSelector((state) => getAppSettings(state));

  const state = notification[WS_TYPE.NOTIFICATION_TYPE.SNACKBAR];

  const handleClose = () => {
    dispatch(
      setWsReduxData({
        type: WS_TYPE.NOTIFICATION,
        data: {
          notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
          open: false,
        },
      })
    );
  };

  return (
    <Snackbar
      key={state.key}
      open={state.open}
      autoHideDuration={state.severity === "success" ? 4000 : undefined}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={SlideTransition}
      sx={{ marginTop: `${appSettings.headerHeight}px` }}
    >
      <Alert
        severity={state.severity}
        elevation={6}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarNotification;
