export const USER_TYPE = {
  ADMIN: "admin",
  USER: "user",
};

export const USER_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const USER_LANGUAGE = {
  EN: "en",
  ZH: "zh-TW",
};
