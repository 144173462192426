import { Box } from "@mui/material";
import {
  endOfMonth,
  getDate,
  isBefore,
  isSameMonth,
  startOfMonth,
} from "date-fns";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import { getUserLanguage, getWallet } from "../../lib/redux/wsSlice";
import { format, toDate, toFixed } from "../../utils";
import api from "../../utils/api";
import Receipt from "./Receipt";
import ReportTooltip from "./ReportTooltip";
import ReportTotal from "./ReportTotal";

const INIT_STATE = {
  reportLoading: false,
  reportData: [],
  reportTotal: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setReport":
      return {
        ...state,
        reportData: action.payload,
        reportLoading: false,
      };
    case "setReportTotal":
      return {
        ...state,
        reportTotal: action.payload,
      };
    default:
      return state;
  }
};

const Month = ({ containerState, containerDispatch }) => {
  const wallet = useSelector((state) => getWallet(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  // const userPids = useSelector((state) => getUserPids(state));
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const month = containerState.selected.month;
  const dateFrom = startOfMonth(month);
  const dateTo = endOfMonth(month);

  let cancelRequest = false;
  useEffect(() => {
    (async () => {
      await loadReportData();
    })();

    () => {
      cancelRequest = true;
      dispatch({ type: "reset" });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
  }, [wallet.expiry_date]);

  const loadReportData = async () => {
    const response = (
      await api().get(`/users/wallet-logs`, {
        params: {
          date_from: dateFrom.toISOString(),
          date_to: dateTo.toISOString(),
        },
      })
    ).data;

    if (cancelRequest) return;

    const data = [];
    let totalEnergy = 0;
    let totalMoney = 0;
    for (
      let dayOfMonth = getDate(dateFrom);
      dayOfMonth <= getDate(dateTo);
      dayOfMonth++
    ) {
      let energy = 0;
      let money = 0;
      let date = new Date(dateFrom);
      response.data.forEach((record) => {
        const recordDate = getDate(toDate(record.createdAt));
        if (recordDate === dayOfMonth) {
          date.setDate(recordDate);
          money += record.payment_intents
            ? record.payment_intents.amount / 100
            : 0;
          energy += record.energy_changed;
        }
      });

      totalEnergy += energy;
      totalMoney += money;
      data.push({
        name: dayOfMonth,
        date,
        energy: parseFloat(toFixed(energy)),
        money,
      });
    }

    dispatch({
      type: "setReportTotal",
      payload: { energy: totalEnergy, money: totalMoney },
    });
    dispatch({ type: "setReport", payload: data });
  };

  const handleClick = (data) => {
    try {
      data = data.activePayload[0].payload;
      if (parseFloat(data.energy) > 0) {
        containerDispatch({ type: "DATE", payload: data.date });
      }
    } catch (e) {}
  };

  const shouldShowReceipt = () => {
    const today = new Date();
    return (
      isBefore(dateFrom, today) === true &&
      isSameMonth(dateFrom, today) === false
    );
  };

  return (
    <Box width="100%">
      <ReportTotal
        title={
          <Box display="flex">
            <Box>
              {format(
                month,
                DATE_FORMAT[userLanguage].MONTH_YEAR,
                userLanguage
              )}
            </Box>
            {shouldShowReceipt() && (
              <Box>
                <Receipt multi={{ date_from: dateFrom, date_to: dateTo }} />
              </Box>
            )}
          </Box>
        }
        total={state.reportTotal}
        back={() => containerDispatch({ type: "LAST_12_MONTH" })}
      />
      <Box width={"100%"} height={300}>
        <ResponsiveContainer>
          <BarChart
            data={state.reportData}
            margin={{ right: 10, left: 0 }}
            fontFamily={"ProductSans, NotoSans"}
            fontSize="12px"
            onClick={handleClick}
          >
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis dataKey="name" stroke={STYLES.PRIMARY_COLOR} />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke={STYLES.PRIMARY_COLOR}
              padding={{ top: 10 }}
              label={{
                value: "kWh",
                position: "insideTop",
                offset: 25,
                fill: STYLES.PRIMARY_COLOR,
              }}
            />
            <Tooltip
              content={(payload) => (
                <ReportTooltip
                  payload={payload}
                  titleFormatter={(payload) =>
                    format(
                      payload.date,
                      DATE_FORMAT[userLanguage].DATE_LONG,
                      userLanguage
                    )
                  }
                />
              )}
            />
            <Bar
              yAxisId="left"
              dataKey="energy"
              fill={STYLES.PRIMARY_COLOR}
              radius={[2, 2, 0, 0]}
              maxBarSize={4}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default Month;
