import { startOfDay, startOfMonth, subDays, subMonths } from "date-fns";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

import { getSelectedCharger } from "../../lib/redux/wsSlice";
import Day from "./Day";
import Last12Month from "./Last12Month";
import Month from "./Month";

const today = new Date();

const INIT_STATE = {
  today,
  dateRange: {
    from: startOfMonth(subMonths(today, 11)),
  },
  selected: {
    month: null,
    date: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LAST_12_MONTH":
      return { ...INIT_STATE };
    case "LAST_30_DAYS":
      return {
        ...state,
        dateRange: {
          from: startOfDay(subDays(today, 29)),
        },
      };
    case "MONTH":
      return {
        ...state,
        selected: {
          month: action.payload,
          date: null,
        },
      };
    case "DATE":
      return {
        ...state,
        selected: {
          month: null,
          date: action.payload,
        },
      };
    default:
      return state;
  }
};

const ChargingReportContainer = () => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const selectedCharger = useSelector((state) => getSelectedCharger(state));
  useEffect(() => {
    dispatch({ type: "LAST_12_MONTH" });
  }, [selectedCharger]);

  return state.selected.month === null && state.selected.date === null ? (
    <Last12Month containerState={state} containerDispatch={dispatch} />
  ) : state.selected.month !== null ? (
    <Month containerState={state} containerDispatch={dispatch} />
  ) : state.selected.date !== null ? (
    <Day containerState={state} containerDispatch={dispatch} />
  ) : null;
};

export default ChargingReportContainer;
