import { Box, Skeleton } from "@mui/material";
import { useMemo } from "react";

const BarChartSkeleton = (props) => {
  const numberOfBars = 12;
  const height = props.height || 300;

  const bars = useMemo(() => {
    const _bars = [];
    for (let i = 0; i <= numberOfBars; i++) {
      const barWitdh = height / 1.5 + i * Math.random() * 10;
      _bars.push(barWitdh);
    }
    return _bars;
  }, [numberOfBars]);

  return (
    <Box {...props}>
      <Box
        width="100%"
        height="100%"
        sx={{
          borderLeft: "1px solid #e0e0e0",
          borderBottom: "1px solid #e0e0e0",
          overflow: "hidden",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          rowGap={1}
          sx={{ transform: "rotate(90deg)" }}
        >
          {bars.map((barWidth, i) => (
            <Skeleton sx={{ width: `${barWidth}px` }} key={i} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BarChartSkeleton;
