import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfDay, format, set, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PID_STATUS, STATUS } from "../constants/charger";
import { getChargerStatus, getSelectedCharger } from "../lib/redux/wsSlice";
import api from "../utils/api";
import IOSSwitch from "./IOSSwitch";

const ChargerSettings = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const selectedCharger = useSelector((state) => getSelectedCharger(state));
  const chargerStatus = useSelector((state) => getChargerStatus(state));

  const defaultAvailableTime = {
    start: format(startOfDay(new Date()), "HH:mm:ss"),
    end: format(endOfDay(new Date()), "HH:mm:ss"),
  };

  const [availableStart, setAvailableStart] = useState(startOfDay(new Date()));
  const [availableEnd, setAvailableEnd] = useState(endOfDay(new Date()));
  const [availableAllDay, setAvailableAllDay] = useState(true);
  const [allowStartCharging, setAllowStartCharging] = useState(true);
  const [pidObj, setPidObj] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleClose = (e, reason) =>
    reason !== "backdropClick" && reason !== "escapeKeyDown" && setOpen(false);

  useEffect(() => {
    (async () => {
      if (open) {
        const pidObj = (await api().get(`/charger/${selectedCharger}`)).data
          .data;
        setPidObj(pidObj);
        setAllowStartCharging(pidObj.status === PID_STATUS.ACTIVE);

        if (pidObj.plan) {
          setAvailableAllDay(false);
          const date = new Date();
          let [hours, minutes, seconds] = pidObj.plan.start_time.split(":");
          setAvailableStart(set(date, { hours, minutes, seconds }));
          [hours, minutes, seconds] = pidObj.plan.end_time.split(":");
          setAvailableEnd(set(date, { hours, minutes, seconds }));

          if (
            pidObj.plan.start_time === defaultAvailableTime.start &&
            pidObj.plan.end_time === defaultAvailableTime.end
          ) {
            setAvailableAllDay(true);
          }
        }
      }
    })();

    return () => {
      setAvailableAllDay(true);
    };
  }, [open]);

  const handleStatusChange = async (event) => {
    setAllowStartCharging(event.target.checked);
    setPidObj({
      ...pidObj,
      status: event.target.checked ? PID_STATUS.ACTIVE : PID_STATUS.INACTIVE,
    });
    setSaving(true);
    await api().patch(
      `/charger/${selectedCharger}/status/${
        event.target.checked ? "active" : "inactive"
      }`
    );
    setSaving(false);
  };

  const handleAvailableChange = async (type, value) => {
    let payload = {
      start: defaultAvailableTime.start,
      end: defaultAvailableTime.end,
    };
    if (type === "start") {
      payload = { [type]: format(value, `HH:mm:00`) };
      setAvailableStart(value);
    } else if (type === "end") {
      payload = { [type]: format(value, `HH:mm:59`) };
      setAvailableEnd(value);
    } else {
      setAvailableAllDay(type.target.checked);
    }

    setSaving(true);
    await api().patch(
      `/charger/${selectedCharger}/service-availability`,
      payload
    );
    setSaving(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t("page.charger.settings.title")}: {selectedCharger}
      </DialogTitle>
      <DialogContent sx={{ padding: 5 }}>
        {pidObj ? (
          <Box display="flex" flexDirection="column" rowGap={2}>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={pidObj.status === PID_STATUS.ACTIVE}
                  onChange={handleStatusChange}
                  disabled={
                    saving ||
                    ![
                      STATUS.AVAILABLE,
                      STATUS.PREPARING,
                      STATUS.OFFLINE,
                      STATUS.UNAVAILABLE,
                      STATUS.TRIPPED,
                    ].includes(chargerStatus)
                  }
                />
              }
              label={
                <Typography sx={{ marginLeft: 1 }} variant="body2">
                  {t("page.charger.settings.status")}
                </Typography>
              }
            />
            <Box
              sx={{ visibility: !allowStartCharging ? "hidden" : "visible" }}
              display="flex"
              flexDirection="column"
              rowGap={2}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={availableAllDay}
                    onChange={(event) => handleAvailableChange(event)}
                    disabled={saving}
                  />
                }
                label={
                  <Typography sx={{ marginLeft: 1 }} variant="body2">
                    {t("page.charger.settings.availability.wholeDay")}
                  </Typography>
                }
              />
              <Box
                sx={{
                  visibility:
                    !allowStartCharging || availableAllDay
                      ? "hidden"
                      : "visible",
                }}
              >
                <Box display="flex" columnGap={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileTimePicker
                      value={availableStart}
                      label={t("page.charger.settings.availability.from")}
                      ampm={false}
                      closeOnSelect={true}
                      slots={{ toolbar: "" }}
                      slotProps={{ actionBar: { actions: ["cancel"] } }}
                      onAccept={(newValue) =>
                        handleAvailableChange("start", newValue)
                      }
                      disabled={saving}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileTimePicker
                      value={availableEnd}
                      label={t("page.charger.settings.availability.to")}
                      ampm={false}
                      closeOnSelect={true}
                      slots={{ toolbar: "" }}
                      slotProps={{ actionBar: { actions: ["cancel"] } }}
                      onAccept={(newValue) =>
                        handleAvailableChange("end", newValue)
                      }
                      disabled={saving}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<CloseIcon />}
          size="small"
          variant="outlined"
          onClick={handleClose}
        >
          {t("common.button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChargerSettings;
