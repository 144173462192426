import { Alert, Box } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { USER_STATUS, USER_TYPE } from "../constants/users";
import {
  getAppSettings,
  getUser,
  getWalletEnergy,
  getWalletLimit,
  isAllowManualTopup,
  isServicePlanExpired,
  isWalletExpired,
} from "../lib/redux/wsSlice";

const StickyNotification = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => getUser(state));
  const servicePlanExpired = useSelector((state) =>
    isServicePlanExpired(state)
  );
  const walletExpired = useSelector((state) => isWalletExpired(state));
  const walletLimit = useSelector((state) => getWalletLimit(state));
  const appSettings = useSelector((state) => getAppSettings(state));
  const allowManualTopup = useSelector((state) => isAllowManualTopup(state));
  const walletEnergy = useSelector((state) => getWalletEnergy(state));

  if (user.status === undefined) return null;

  const StickyAlert = () => {
    if (!user.type === USER_TYPE.USER) return null;

    if (user.status !== USER_STATUS.ACTIVE) {
      return (
        <Alert variant="outlined" severity="warning">
          <Box>{t("stickyNotification.account.inactive")}</Box>
          {servicePlanExpired && t("stickyNotification.account.serviceExpired")}
        </Alert>
      );
    }

    if (allowManualTopup) {
      if (walletEnergy <= 0) {
        return (
          <Alert variant="outlined" severity="error">
            {t("stickyNotification.charger.suspended.insufficientEnergy")}
          </Alert>
        );
      }

      if (walletExpired) {
        return (
          <Alert variant="outlined" severity="error">
            {t("stickyNotification.charger.suspended.walletExpired")}
          </Alert>
        );
      }

      if (walletEnergy > 0 && walletEnergy <= walletLimit.threshold) {
        return (
          <Alert variant="outlined" severity="warning">
            {t("stickyNotification.charger.suspended.soon")}
          </Alert>
        );
      }
    }

    return null;
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
        top: appSettings.headerHeight,
        position: "sticky",
        backgroundColor: "white",
        zIndex: zIndex.fab,
      }}
    >
      <StickyAlert />
    </Box>
  );
};

export default StickyNotification;
