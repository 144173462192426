import { useEffect, useState } from "react";

import api from "../utils/api";

export function useAuthStatus() {
  const [result, setResult] = useState({
    isLoadingAuth: true,
    isAuthorized: false,
    userId: "",
    token: "",
  });

  useEffect(() => {
    let cancelRequest = false;

    (async () => {
      try {
        const { authStatus, identifier, token } = (await api().post(`/auth`))
          .data;
        if (cancelRequest) {
          return;
        }

        if (authStatus === "success") {
          setResult({
            isLoadingAuth: false,
            isAuthorized: authStatus,
            userId: identifier,
            token,
          });
        } else {
          setResult({
            isLoadingAuth: false,
            isAuthorized: false,
            userId: "",
            token: "",
          });
        }
      } catch (e) {
        console.log(e);
        setResult({
          isLoadingAuth: false,
          isAuthorized: false,
          userId: "",
          token: "",
        });
      }
    })();

    return () => {
      cancelRequest = true;
    };
  }, []);

  return result;
}
