import ReactSpeedometer from "react-d3-speedometer";
import { useSelector } from "react-redux";

import { MAX_CURRENT, STATUS } from "../constants/charger";
import { STYLES } from "../constants/styles";
import { getCharger } from "../lib/redux/wsSlice";

const ChargerMeter = () => {
  const charger = useSelector((state) => getCharger(state));

  let currentImport = 0;
  if (charger.status === STATUS.CHARGING) {
    currentImport = charger?.message?.currentImport || 0;
  }

  return (
    <ReactSpeedometer
      paddingVertical={20}
      maxValue={MAX_CURRENT}
      minValue={0}
      height={180}
      width={300}
      value={currentImport}
      currentValueText={`${currentImport} A`}
      needleTransition="easeExpOut"
      needleColor={"red"}
      needleHeightRatio={0.7}
      segments={5}
      valueFormat={"d"}
      startColor={STYLES.PRIMARY_COLOR_LIGHT}
      endColor={STYLES.PRIMARY_COLOR}
      segmentValueFormatter={(value) => `${parseFloat(value).toFixed(0)}A`}
    />
  );
};

export default ChargerMeter;
