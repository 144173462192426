import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from "./locales/resources";

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: "en",
  debug: process.env.REACT_APP_DEBUG === "true",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

i18n.on("languageChanged", (language) => {
  console.log(language);
  document.title = i18n.t("document.title");
});

export default i18n;
