import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminHome from "../components/Home/AdminHome";
import UserHome from "../components/Home/UserHome";
import HomeLayout from "../components/HomeLayout";
import { USER_TYPE } from "../constants/users";
import { useAuthStatus } from "../hooks/useAuthStatus";
import { getUserType } from "../lib/redux/wsSlice";

const Home = () => {
  const navigate = useNavigate();
  const { isLoadingAuth, isAuthorized, userId, token } = useAuthStatus();
  const userType = useSelector((state) => getUserType(state));

  useEffect(() => {
    let cancelRequest = false;
    (async () => {
      if (isLoadingAuth || cancelRequest) {
        return;
      } else if (!isAuthorized) {
        navigate("/");
      }
    })();

    return () => {
      cancelRequest = true;
    };
  }, [isLoadingAuth, isAuthorized]);

  if (isLoadingAuth) {
    return;
  }

  return (
    <HomeLayout userId={userId} token={token}>
      {userType === USER_TYPE.ADMIN && <AdminHome />}
      {userType === USER_TYPE.USER && <UserHome />}
    </HomeLayout>
  );
};

export default Home;
