import { Box } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { STYLES } from "../constants/styles";
import { getAppSettings } from "../lib/redux/wsSlice";

const Login = () => {
  const appSettings = useSelector((state) => getAppSettings(state));
  useEffect(() => {
    require("@passageidentity/passage-elements/passage-login");
  }, []);

  return (
    <Box width="100%" display="flex" flexDirection="column" rowGap={1}>
      <Box
        maxWidth="inherit"
        width="inherit"
        height={`${appSettings.headerHeight}px`}
        backgroundColor={STYLES.PRIMARY_COLOR}
        p={1}
        sx={{
          backgroundImage: "url(/logo.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          padding: 0,
        }}
      ></Box>

      <Box>
        <passage-login
          app-id={process.env.REACT_APP_PASSAGE_APP_ID}
          default-country-code="hk"
        ></passage-login>
      </Box>
    </Box>
  );
};

export default Login;
