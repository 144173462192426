import { Box } from "@mui/material";
import axios from "axios";
import React, { createContext } from "react";
import { useSelector } from "react-redux";

import { USER_TYPE } from "../constants/users";
import {
  getAppSettings,
  getUserAgreeTerms,
  getUserHasPaymentMethod,
  getUserType,
} from "../lib/redux/wsSlice";
import { debug } from "../utils";
import Footer from "./Footer";
import Header from "./Header";
import HeaderAdmin from "./HeaderAdmin";
import SnackBarNotification from "./SnackBarNotification";
import StickyNotification from "./StickyNotification";
import StripSetup from "./StripeSetup";
import TermsAndConditions from "./TermsAndConditions";
import WebSocketFactory from "./WebSocketFactory";

axios.interceptors.response.use(
  (response) => response,
  (e) => {
    if (e.response.status !== 400) {
      debug(e.response);
      window.location.href = `/?error=auth`;
    } else {
      return Promise.reject(e.response);
    }
  }
);

export const HomeContext = createContext();

const HomeLayout = (props) => {
  const appSettings = useSelector((state) => getAppSettings(state));
  const userType = useSelector((state) => getUserType(state));
  const agreeTerms = useSelector((state) => getUserAgreeTerms(state));
  const hasPaymentMethod =
    useSelector((state) => getUserHasPaymentMethod(state)) ||
    userType === USER_TYPE.ADMIN;

  const { userId, token } = props;

  return (
    <HomeContext.Provider value={{ userId, token }}>
      <WebSocketFactory />
      {userType === USER_TYPE.ADMIN && <HeaderAdmin />}
      {userType === USER_TYPE.USER && <Header />}

      {userType && (
        <Box
          sx={{
            paddingBottom: `${appSettings.headerHeight}px`,
            marginLeft: 1,
            marginRight: 1,
          }}
        >
          {!agreeTerms ? (
            <TermsAndConditions needAction={true} />
          ) : !hasPaymentMethod ? (
            <StripSetup />
          ) : (
            <>
              <SnackBarNotification />

              <StickyNotification />
              {props.children}
            </>
          )}
        </Box>
      )}

      <Footer />
    </HomeContext.Provider>
  );
};

export default HomeLayout;
