import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { STYLES } from "../../constants/styles";

const ReportTooltip = ({ payload, titleFormatter }) => {
  const { t } = useTranslation();
  payload = payload.payload?.[0]?.payload || null;
  if (!payload) return null;

  if (parseFloat(payload.energy) === 0) return null;

  const title = titleFormatter(payload);

  return (
    <Paper sx={{ padding: 2, color: STYLES.PRIMARY_COLOR }} elevation={6}>
      <Typography component="div" variant="h5">
        {title}
      </Typography>
      <Box display="flex" columnGap={2}>
        <Box>
          <Typography
            component="div"
            variant="caption"
            style={{ color: "grey" }}
          >
            {t("page.wallet.report.topupedUpEnergy")}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography variant="subtitle">{payload.energy}</Typography>
            <Typography variant="caption">kWh</Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            component="div"
            variant="caption"
            style={{ color: "grey" }}
          >
            {t("page.wallet.report.topupedUpAmount")}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography variant="subtitle">{payload.money}</Typography>
            <Typography variant="caption">HKD</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ReportTooltip;
