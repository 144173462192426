import { configureStore } from "@reduxjs/toolkit";

import wsReducer from "./wsSlice";

export default configureStore({
  reducer: {
    wsData: wsReducer,
  },
  devTools: process.env.REACT_APP_APP_ENV !== "production",
});
