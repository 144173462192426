import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setWsReduxData } from "../lib/redux/wsSlice";
import websocket from "../lib/websocket";
import { debug } from "../utils";
import { HomeContext } from "./HomeLayout";

const WebSocketFactory = () => {
  const dispatch = useDispatch();
  const { token } = useContext(HomeContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (token) {
      const { disconnect } = websocket.connect(token, (message) => {
        debug(message);

        if (message.data?.language) {
          i18n.changeLanguage(message.data.language);
        }

        dispatch(setWsReduxData(message));
      });

      return () => {
        disconnect();
      };
    }
  }, [token]);

  return null;
};

export default WebSocketFactory;
