import "./styles/globals.css";

import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Provider } from "react-redux";

import AppContainer from "./components/AppContainer";
import store from "./lib/redux/store";

const theme = createTheme({
  typography: {
    fontFamily: "ProductSans, NotoSans",
  },
});

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV !== "development") {
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
