import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useReducer } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TbRecharging } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import { STYLES } from "../constants/styles";
import { WS_TYPE } from "../constants/ws";
import useQuery from "../hooks/useQuery";
import {
  getTopupConvertionRate,
  getUser,
  getWallet,
  getWalletLimit,
  isAllowManualTopup,
  isServicePlanExpired,
  setWsReduxData,
} from "../lib/redux/wsSlice";
import { isNilOrEmpty } from "../utils";
import api from "../utils/api";
import ConfirmationDialog from "./ConfirmationDialog";

const INIT_STATE = {
  topuping: false,
  confirmOpen: false,
  paymentMethod: {},
  loadingPaymentMethod: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setPaymentMethod":
      return {
        ...state,
        loadingPaymentMethod: false,
        paymentMethod: action.payload,
      };
    case "topuping":
      return { ...state, topuping: true };
    case "topupSuccess":
      return {
        ...state,
        topuping: false,
      };
    case "topupFailed":
      return {
        ...state,
        topuping: false,
      };
    case "confirm":
      return { ...state, confirmOpen: true, loadingPaymentMethod: true };
    case "confirmClose":
      return { ...state, confirmOpen: false, paymentMethod: {} };
    default:
      return state;
  }
};

const TopupButton = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const sessionId = query.get("sessionId");
  const dispatchNotification = useDispatch();
  const user = useSelector((state) => getUser(state));
  const servicePlanExpired = useSelector((state) =>
    isServicePlanExpired(state)
  );
  const topupConvertionRate = useSelector((state) =>
    getTopupConvertionRate(state)
  );
  const walletLimit = useSelector((state) => getWalletLimit(state));
  const wallet = useSelector((state) => getWallet(state));
  const allowManualTopup = useSelector((state) => isAllowManualTopup(state));

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  useEffect(() => {
    (async () => {
      if (sessionId) {
        const paymentSession = (
          await api().get(`/payment/session/${sessionId}`)
        ).data;
        if (paymentSession.status_code === 1000) {
          dispatchNotification(
            setWsReduxData({
              type: WS_TYPE.NOTIFICATION,
              data: {
                notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
                open: true,
                severity: "success",
                message: t("page.wallet.topup.dialog.successMsg", {
                  amount: paymentSession.data.amount_total / 100,
                }),
              },
            })
          );
        }
      }
    })();
  }, [sessionId]);

  const handleTopupClick = async () => {
    dispatch({ type: "confirm" });
    const paymentMethod = (await api().get("/users/payment/get-payment-method"))
      .data;
    dispatch({ type: "setPaymentMethod", payload: paymentMethod.data });
  };

  const handleTopupConfirm = async (changeCard = false) => {
    if (!isNilOrEmpty(user.stripe?.payment_method) && !changeCard) {
      try {
        dispatch({ type: "topuping" });
        const response = (
          await api().post("/users/payment/topup-quick", {
            amount: topupConvertionRate.topup,
          })
        ).data;

        if (response.status_code !== 1000) {
          throw new Error(response.status_message);
        }

        const { amount } = response.data;

        dispatch({ type: "topupSuccess" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "success",
              message: t("page.wallet.topup.dialog.successMsg", { amount }),
            },
          })
        );
      } catch (e) {
        console.log(e);
        dispatch({ type: "topupFailed" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "error",
              message: e.message,
            },
          })
        );
      }
    } else {
      try {
        const { redirect } = (
          await api().post("/users/payment/topup", {
            amount: topupConvertionRate.topup,
          })
        ).data.data;
        window.location.href = redirect;
      } catch (e) {
        console.log(e);
        dispatch({ type: "topupFailed" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "error",
              message: e.data,
            },
          })
        );
      }
    }
  };

  if (!allowManualTopup) {
    return;
  }

  if (allowManualTopup && wallet.energy >= walletLimit.max) {
    return (
      <Alert variant="outlined" severity="info" sx={{ width: "100%" }}>
        {t("page.wallet.maxReached")}
      </Alert>
    );
  }

  if (servicePlanExpired) {
    return;
  }

  return (
    <>
      <LoadingButton
        loading={state.topuping}
        variant="contained"
        onClick={handleTopupClick}
        fullWidth
        startIcon={<TbRecharging size={40} />}
        sx={{
          backgroundColor: STYLES.SECONDARY_COLOR,
          filter: "drop-shadow(1px 1px 1px black)",
          borderRadius: 2,
        }}
      >
        {t("common.button.topup")}
      </LoadingButton>

      <ConfirmationDialog
        content={
          <Stack>
            {
              <Typography variant="subtitle">
                <Trans
                  i18nKey="page.wallet.topup.dialog.rate"
                  values={{
                    topup: topupConvertionRate.topup,
                    energy: topupConvertionRate.energy,
                  }}
                  components={[<Typography key={1} variant="caption" />]}
                />
              </Typography>
            }
            {!isNilOrEmpty(state.paymentMethod) && (
              <TextField
                label={state.paymentMethod.brand.toUpperCase()}
                value={`\u{FF0E}\u{FF0E}\u{FF0E}\u{FF0E}${state.paymentMethod.last4}`}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => handleTopupConfirm(true)}
                    >
                      <Button>{t("common.button.change")}</Button>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                size="small"
                sx={{ marginTop: 5 }}
              />
            )}
          </Stack>
        }
        confirmAction={handleTopupConfirm}
        open={state.confirmOpen}
        loadingButton
        loadingButtonState={state.loadingPaymentMethod || state.topuping}
        handleClose={() => dispatch({ type: "confirmClose" })}
      />
    </>
  );
};

export default TopupButton;
