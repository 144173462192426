import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { STYLES } from "../constants/styles";

const ConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const {
    title,
    content,
    confirmAction,
    open,
    handleClose,
    loadingButton = false,
    loadingButtonState = false,
  } = props;

  const handleCancel = async () => {
    handleClose();
  };

  const handleOk = async () => {
    if (confirmAction.constructor.name === "AsyncFunction") {
      await confirmAction();
    } else {
      confirmAction();
    }
    handleClose();
  };

  const _handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  const ActionButton = (props) => {
    return loadingButton ? (
      <LoadingButton loading={loadingButtonState} {...props}>
        {props.children}
      </LoadingButton>
    ) : (
      <Button {...props}>{props.children}</Button>
    );
  };

  return (
    <Dialog open={open} onClose={_handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          backgroundColor: STYLES.PRIMARY_COLOR,
          color: STYLES.PRIMARY_TEXT_COLOR,
          ...(!title && { paddingTop: 1, paddingBottom: 1 }),
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 2 }}>
        {content}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Box display="flex" justifyContent={"space-between"} width="100%">
          <ActionButton
            sx={{
              flexGrow: 1,
              backgroundColor: STYLES.PRIMARY_COLOR,
              color: STYLES.PRIMARY_TEXT_COLOR,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              ".MuiLoadingButton-loadingIndicator": {
                color: STYLES.PRIMARY_TEXT_COLOR,
              },
            }}
            onClick={handleOk}
            startIcon={<DoneIcon />}
          >
            {t("common.button.confirm")}
          </ActionButton>
          <Button
            sx={{
              flexGrow: 1,
              backgroundColor: "#3e7d8175",
              color: STYLES.PRIMARY_TEXT_COLOR,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={handleCancel}
            startIcon={<CloseIcon />}
            disabled={loadingButtonState}
          >
            {t("common.button.cancel")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
