import SettingsIcon from "@mui/icons-material/Settings";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { STATUS } from "../constants/charger";
import { STYLES } from "../constants/styles";
import { USER_LANGUAGE } from "../constants/users";
import {
  getCharger,
  getChargerStatus,
  getChargers,
  getPids,
  getSelectedCharger,
  getUserLanguage,
  getUserPids,
  setSelectedCharger,
} from "../lib/redux/wsSlice";
import { getMeterColorFilter } from "../utils";
import ChargerMeter from "./ChargerMeter";
import ChargerMeterPlaceHolder from "./ChargerMeterPlaceHolder";
import ChargerSettings from "./ChargerSetting";

const ChargerMeterContainer = () => {
  const { t } = useTranslation();
  const charger = useSelector((state) => getCharger(state));
  const chargers = useSelector((state) => getChargers(state));
  const chargerStatus = useSelector((state) => getChargerStatus(state));
  const [loading, setLoading] = useState(true);
  const pids = useSelector((state) => getPids(state));
  const selectedCharger = useSelector((state) => getSelectedCharger(state));
  const userPids = useSelector((state) => getUserPids(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));

  const dispatch = useDispatch();

  const [chargerSettingsOpen, setChargerSettingsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleChangeSelectedCharger = (event, value) => {
    if (value) {
      dispatch(setSelectedCharger(value.id));
    }
  };

  const sx = { filter: getMeterColorFilter(chargerStatus) };

  if (loading) {
    return (
      <Box display="flex" flexDirection="column">
        <Box alignSelf="center" height={125} sx={{ overflow: "hidden" }} my={5}>
          <Skeleton variant="circular" width={250} height={250} />
        </Box>
        <Box alignSelf="center">
          <Skeleton variant="rounded" width={80} height={24} />
        </Box>
      </Box>
    );
  }

  return (
    <Box width={"100%"}>
      {userPids.length > 1 && (
        <Box>
          <Autocomplete
            options={Object.keys(pids).map((pid) => ({
              id: pid,
              parking_lot: pids[pid].parking_lot,
              carpark_name:
                pids[pid][
                  userLanguage === USER_LANGUAGE.ZH
                    ? "carpark_name_chinese"
                    : "carpark_name"
                ],
              label: `${pids[pid].parking_lot} - ${pid}`,
            }))}
            isOptionEqualToValue={(option, value) => option.id === value}
            renderInput={(params) => (
              <TextField {...params} label={t("header.charger")} />
            )}
            value={selectedCharger}
            onChange={handleChangeSelectedCharger}
            groupBy={(option) => option.carpark_name}
            size="small"
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Box display="flex" columnGap={2} alignItems="center">
                    <SpeedIcon
                      size={4}
                      style={{
                        color: STYLES.PRIMARY_COLOR_LIGHT,
                        filter: getMeterColorFilter(
                          chargers[option.id]?.status
                        ),
                        transform: `scaleX(${
                          chargers[option.id]?.status !== STATUS.CHARGING
                            ? "-1"
                            : "1"
                        })`,
                      }}
                    />
                    <Typography>{option.parking_lot}</Typography>
                    <Typography variant="caption">{option.id}</Typography>
                  </Box>
                </li>
              );
            }}
          />
        </Box>
      )}
      <Box {...{ sx }} textAlign="center">
        {charger?.message?.currentOffered > 0 ? (
          <ChargerMeter />
        ) : (
          <ChargerMeterPlaceHolder />
        )}
        <Box display="flex" justifyContent="center">
          <Chip
            size="small"
            label={
              userPids.length > 1 ? (
                <>
                  <Button
                    startIcon={<SettingsIcon />}
                    onClick={() => setChargerSettingsOpen(true)}
                  >
                    {t(
                      `page.charger.ocppStatus.${chargerStatus.toLowerCase()}`
                    ).toUpperCase()}
                  </Button>
                  <ChargerSettings
                    open={chargerSettingsOpen}
                    setOpen={setChargerSettingsOpen}
                  />
                </>
              ) : (
                <Typography variant="caption">
                  {t(
                    `page.charger.ocppStatus.${chargerStatus.toLowerCase()}`
                  ).toUpperCase()}
                </Typography>
              )
            }
            variant="outlined"
            sx={{
              border: `1px solid ${STYLES.PRIMARY_COLOR}`,
              color: STYLES.PRIMARY_COLOR,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChargerMeterContainer;
