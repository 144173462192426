import {
  formatDuration,
  intervalToDuration,
  isAfter,
  secondsToMilliseconds,
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { enUS, zhTW } from "date-fns/locale";
import * as R from "ramda";

import { STATUS } from "../constants/charger";
import { DATE_FORMAT, TIME_ZONE } from "../constants/system";
import { USER_LANGUAGE } from "../constants/users";

const isNilOrEmpty = (value) =>
  R.anyPass([R.isEmpty, R.isNil, Number.isNaN])(value);

const toDate = (date) => {
  if (date instanceof Date === false) {
    if (typeof date === "number") {
      if (`${date}`.length === 10) {
        date = date * 1000;
      }
    }
  }
  return new Date(date);
};

/**
 * is date1 after date2
 */
const isDateAfter = (date1, date2) => {
  date1 = toDate(date1);
  date2 = toDate(date2);
  return isAfter(date1, date2);
};

const debug = (log) => {
  if (process.env.REACT_APP_DEBUG === "true") {
    console.log(log);
  }
};

const format = (
  date = new Date(),
  format = DATE_FORMAT[USER_LANGUAGE.EN].DATETIME,
  locale = USER_LANGUAGE.EN
) => {
  locale = locale === USER_LANGUAGE.EN ? enUS : zhTW;
  return formatInTimeZone(toDate(date), TIME_ZONE, format, { locale });
};

const secondsToDuration = (seconds) => {
  const epoch = new Date(0);
  const secondsAfterEpoch = new Date(secondsToMilliseconds(seconds));
  const duration = intervalToDuration({
    start: epoch,
    end: secondsAfterEpoch,
  });

  const format = {
    xSeconds: "{{count}}s",
    xMinutes: "{{count}}m",
    xHours: "{{count}}h",
    xDays: "{{count}}d",
  };
  const locale = {
    formatDistance: (token, count) =>
      format[token]?.replace("{{count}}", count),
  };

  return {
    formatted: formatDuration(duration, { locale }),
    duration,
  };
};

const toFixed = (value, precision = 3) => {
  if (value === undefined || value === null) {
    return 0;
  }

  let strValue = `${value}`;
  strValue = strValue.split(".");

  if (!strValue[1]) {
    return value;
  }

  return precision === 0 || strValue.length <= 1
    ? strValue[0]
    : `${strValue[0]}.${strValue[1].slice(0, precision)}`;
};

const getMeterColorFilter = (status) => {
  switch (status?.toUpperCase()) {
    case STATUS.AVAILABLE.toUpperCase(): {
      return "hue-rotate(335deg)";
    }
    case STATUS.PREPARING.toUpperCase(): {
      return "hue-rotate(270deg)";
    }
    case STATUS.CHARGING.toUpperCase(): {
      return "hue-rotate(0deg)";
    }
    case STATUS.SUSPENDEDEV.toUpperCase(): {
      return "hue-rotate(70deg)";
    }
    case STATUS.SUSPENDEDEVSE.toUpperCase(): {
      return "hue-rotate(70deg)";
    }
    case STATUS.FINISHING.toUpperCase(): {
      return "hue-rotate(200deg)";
    }
    case STATUS.FAULTED.toUpperCase(): {
      return "hue-rotate(180deg)";
    }
    default:
      return "grayscale(1)";
  }
};

export {
  toDate,
  isDateAfter,
  debug,
  isNilOrEmpty,
  secondsToDuration,
  format,
  toFixed,
  getMeterColorFilter,
};
