import { Box } from "@mui/material";
import { endOfMonth, getDate, startOfMonth } from "date-fns";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import {
  getChargerStatus,
  getSelectedCharger,
  getUserLanguage,
} from "../../lib/redux/wsSlice";
import { format, isNilOrEmpty, toDate, toFixed } from "../../utils";
import api from "../../utils/api";
import BarChartSkeleton from "../BarChartSkeleton";
import ReportTooltip from "./ReportTooltip";
import ReportTotal from "./ReportTotal";

const INIT_STATE = {
  reportLoading: false,
  reportData: [],
  reportTotal: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setReport":
      return {
        ...state,
        reportData: action.payload,
        reportLoading: false,
      };
    case "setReportTotal":
      return {
        ...state,
        reportTotal: action.payload,
      };
    default:
      return state;
  }
};

const Month = ({ containerState, containerDispatch }) => {
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const chargerStatus = useSelector((state) => getChargerStatus(state));
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const selectedCharger = useSelector((state) => getSelectedCharger(state));

  const month = containerState.selected.month;

  let cancelRequest = false;
  useEffect(() => {
    (async () => {
      await loadReportData();
    })();

    () => {
      cancelRequest = true;
      dispatch({ type: "reset" });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
  }, [chargerStatus, selectedCharger]);

  const loadReportData = async () => {
    const dateFrom = startOfMonth(month);
    const dateTo = endOfMonth(month);

    const response = (
      await api().get(`/charger/${selectedCharger}/charging-records`, {
        params: {
          date_from: dateFrom.toISOString(),
          date_to: dateTo.toISOString(),
        },
      })
    ).data;

    if (cancelRequest) return;

    const data = [];
    let total = 0;
    let totalTime = 0;
    for (
      let dayOfMonth = getDate(dateFrom);
      dayOfMonth <= getDate(dateTo);
      dayOfMonth++
    ) {
      let kWh = 0;
      let mintues = 0;
      let date = new Date(dateFrom);
      response.data.forEach((record) => {
        const recordDate = getDate(toDate(record.ctime));
        if (recordDate === dayOfMonth) {
          date.setDate(recordDate);
          kWh += record.charged_energy;
          mintues += record.charged_time / 60;
        }
      });

      total += kWh;
      totalTime += mintues;
      data.push({
        name: dayOfMonth,
        date,
        kWh: parseFloat(toFixed(kWh)),
        mintues,
      });
    }

    dispatch({
      type: "setReportTotal",
      payload: { energy: total, time: totalTime },
    });
    dispatch({ type: "setReport", payload: data });
  };

  const handleClick = (data) => {
    try {
      data = data.activePayload[0].payload;
      if (parseFloat(data.kWh) > 0) {
        containerDispatch({ type: "DATE", payload: data.date });
      }
    } catch (e) {}
  };

  return (
    <Box width="100%">
      <ReportTotal
        title={format(
          month,
          DATE_FORMAT[userLanguage].MONTH_YEAR,
          userLanguage
        )}
        total={state.reportTotal}
        back={() => containerDispatch({ type: "LAST_12_MONTH" })}
      />
      <Box width={"100%"} height={300}>
        {isNilOrEmpty(state.reportData) ? (
          <BarChartSkeleton height={300} sx={{ ml: 7, mr: 1 }} />
        ) : (
          <ResponsiveContainer>
            <BarChart
              data={state.reportData}
              margin={{ right: 10, left: 0 }}
              fontFamily={"ProductSans, NotoSans"}
              fontSize="12px"
              onClick={handleClick}
            >
              <CartesianGrid strokeDasharray="1 3" />
              <XAxis dataKey="name" stroke={STYLES.PRIMARY_COLOR} />
              <YAxis
                yAxisId="kwh"
                orientation="left"
                stroke={STYLES.PRIMARY_COLOR}
                padding={{ top: 10 }}
                label={{
                  value: "kWh",
                  position: "insideTop",
                  offset: 25,
                  fill: STYLES.PRIMARY_COLOR,
                }}
              />
              <Tooltip
                content={(payload) => (
                  <ReportTooltip
                    payload={payload}
                    titleFormatter={(payload) =>
                      format(
                        payload.date,
                        DATE_FORMAT[userLanguage].DATE_LONG,
                        userLanguage
                      )
                    }
                  />
                )}
              />
              <Bar
                yAxisId="kwh"
                dataKey="kWh"
                fill={STYLES.PRIMARY_COLOR}
                radius={[2, 2, 0, 0]}
                maxBarSize={4}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
};

export default Month;
