import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import {
  getChargerStatus,
  getSelectedCharger,
  getUserLanguage,
} from "../../lib/redux/wsSlice";
import { format, secondsToDuration, toDate } from "../../utils";
import api from "../../utils/api";
import ReportTotal from "./ReportTotal";

const INIT_STATE = {
  reportLoading: false,
  reportData: [],
  reportTotal: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setReport":
      return {
        ...state,
        reportData: action.payload,
        reportLoading: false,
      };
    case "setReportTotal":
      return {
        ...state,
        reportTotal: action.payload,
      };
    default:
      return state;
  }
};

const Day = ({ containerState, containerDispatch }) => {
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const chargerStatus = useSelector((state) => getChargerStatus(state));
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const selectedCharger = useSelector((state) => getSelectedCharger(state));
  const selectedDate = toDate(containerState.selected.date);

  let cancelRequest = false;
  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
    () => {
      cancelRequest = true;
      dispatch({ type: "reset" });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
  }, [chargerStatus, selectedCharger]);

  const loadReportData = async () => {
    const dateFrom = startOfDay(selectedDate);
    const dateTo = endOfDay(selectedDate);

    const response = (
      await api().get(`/charger/${selectedCharger}/charging-records`, {
        params: {
          date_from: dateFrom.toISOString(),
          date_to: dateTo.toISOString(),
        },
      })
    ).data;

    if (cancelRequest) return;

    const data = response.data;
    const total = data.reduce((acc, record) => acc + record.charged_energy, 0);
    const totalTime = data.reduce(
      (acc, record) => acc + record.charged_time,
      0
    );

    dispatch({
      type: "setReportTotal",
      payload: { energy: total, time: totalTime / 60 },
    });
    dispatch({
      type: "setReport",
      payload: data.filter((d) => d.charged_energy > 0),
    });
  };

  return (
    <Box width="100%">
      <ReportTotal
        title={format(
          selectedDate,
          DATE_FORMAT[userLanguage].DATE_LONG,
          userLanguage
        )}
        total={state.reportTotal}
        back={() => containerDispatch({ type: "MONTH", payload: selectedDate })}
      />

      <Stack px={2} rowGap={2}>
        {state.reportData.map((data, i) => (
          <span key={data.id}>
            <Box width="100%" display="flex" alignItems="center">
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography variant="caption" style={{ color: "grey" }}>
                  {state.reportData.length - i}.
                </Typography>

                <Box>
                  <Typography
                    variant="h6"
                    style={{ color: STYLES.PRIMARY_COLOR }}
                  >
                    {format(
                      data.charger_start,
                      DATE_FORMAT[userLanguage].TIME12
                    )}
                  </Typography>

                  <Box display="flex" columnGap={1}>
                    <Box display="flex" alignItems="center" columnGap="0.1rem">
                      <KeyboardDoubleArrowRightOutlinedIcon
                        fontSize="small"
                        style={{ color: "grey" }}
                      />
                      <Typography variant="caption" style={{ color: "grey" }}>
                        {format(
                          data.charger_end,
                          DATE_FORMAT[userLanguage].TIME12
                        )}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" columnGap="0.1rem">
                      <AccessTimeFilledIcon
                        style={{
                          fontSize: "0.9rem",
                          marginLeft: 8,
                          color: "grey",
                        }}
                      />
                      <Typography variant="caption" style={{ color: "grey" }}>
                        {` ${secondsToDuration(data.charged_time).formatted}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box marginLeft="auto">
                <Typography
                  variant="h6"
                  component="span"
                  style={{ color: STYLES.PRIMARY_COLOR }}
                >
                  {data.charged_energy}
                </Typography>
                &nbsp;
                <Typography
                  variant="subtitle2"
                  component="span"
                  style={{ color: STYLES.PRIMARY_COLOR }}
                >
                  kWh
                </Typography>
              </Box>
            </Box>
            <Divider />
          </span>
        ))}
      </Stack>
    </Box>
  );
};

export default Day;
