import axios from "axios";

export const cancelTokenSource = axios.CancelToken.source();

const api = () => {
  const axiosConfig = { timeout: 10000 };

  const token = localStorage.getItem("psg_auth_token");
  axiosConfig.headers = { Authorization: `Bearer ${token}` };
  axiosConfig.baseURL = process.env.REACT_APP_API_URL;
  axiosConfig.cancelToken = cancelTokenSource.token;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = `/`;
      }
      return Promise.reject(error);
    }
  );

  return axios.create(axiosConfig);
};

export default api;
