import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isToday, subDays } from "date-fns";

import { STATUS } from "../../constants/charger";
import { USER_LANGUAGE } from "../../constants/users";
import { WS_ACTION, WS_TYPE } from "../../constants/ws";
import { isDateAfter, isNilOrEmpty } from "../../utils";

const initialState = {
  notification: {
    [WS_TYPE.NOTIFICATION_TYPE.SNACKBAR]: {
      key: Date.now(),
      open: false,
      closeBtn: false,
      message: "",
      severity: "success",
    },
  },
};

export const wsSlice = createSlice({
  name: "ws",
  initialState: {
    user: {},
    charger: {},
    controllerInfo: {},
    system_metas: [],
    pids: {},
    appSettings: {
      headerHeight: 60,
    },
    notification: {
      [WS_TYPE.NOTIFICATION_TYPE.SNACKBAR]:
        initialState.notification[WS_TYPE.NOTIFICATION_TYPE.SNACKBAR],
    },
    appData: {
      selectedCharger: null,
    },
  },
  reducers: {
    setWsReduxData: (state, action) => {
      const type = action.payload.type;
      switch (type) {
        case WS_TYPE.USER: {
          state.user = action.payload.data;
          if (!state.appData.selectedCharger) {
            state.appData.selectedCharger = state.user.pids[0].pid;
          }
          break;
        }
        case WS_TYPE.PIDS:
          state.pids = action.payload.data;
          break;
        case WS_TYPE.SYSTEM_METAS:
          state.system_metas = action.payload.data;
          break;
        case WS_TYPE.NOTIFICATION: {
          const { notificationType } = action.payload.data;
          const payload = action.payload.data;
          if (payload.open) {
            state.notification[notificationType] = {
              ...initialState.notification[WS_TYPE.NOTIFICATION_TYPE.SNACKBAR],
            };
            state.notification[notificationType] = {
              ...state.notification[notificationType],
              open: true,
              severity: payload.severity,
              message: payload.message,
            };
            if (payload.props) {
              state.notification[notificationType] = {
                ...state.notification[notificationType],
                ...payload.props,
              };
            }
          } else {
            state.notification[notificationType] = {
              ...state.notification[notificationType],
              open: false,
            };
          }
          break;
        }
        case WS_TYPE.CHARGER: {
          switch (action.payload.data.action) {
            case WS_ACTION.CHARGER_METER_VALUES: {
              state.charger = {
                ...state.charger,
                [action.payload.data.payload.pid]: {
                  ...action.payload.data.payload,
                },
              };
              break;
            }
            case WS_ACTION.CHARGER_STATUS: {
              state.charger = {
                ...state.charger,
                [action.payload.data.payload.pid]: {
                  status: action.payload.data.payload.status,
                },
              };
              break;
            }
          }
          break;
        }
        case WS_TYPE.CONTROLLER_INFO: {
          state.controllerInfo = {
            ...state.controllerInfo,
            [action.payload.data.carpark_id]: action.payload.data,
          };
          break;
        }
      }
    },
    setSelectedCharger: (state, action) => {
      state.appData.selectedCharger = action.payload;
    },
  },
});

export const { setWsReduxData, setSelectedCharger } = wsSlice.actions;

export default wsSlice.reducer;

/**
 * Selector
 */
export const getWsData = (state) => state.wsData;
export const getAppData = (state) => state.wsData.appData;

export const getSelectedCharger = createSelector(
  getAppData,
  (appData) => appData.selectedCharger
);

export const getAppSettings = createSelector(
  getWsData,
  (wsData) => wsData.appSettings
);

export const getNotification = createSelector(
  getWsData,
  (wsData) => wsData.notification
);

export const getUser = createSelector(
  getWsData,
  (wsData) => wsData?.user || {}
);

export const getUserPids = createSelector(
  getWsData,
  (wsData) => wsData?.user?.pids || []
);

export const getUserType = createSelector(
  getWsData,
  (wsData) => wsData.user?.type || ""
);

export const getUserAgreeTerms = createSelector(
  getWsData,
  (wsData) => wsData.user?.agree_terms || false
);

export const getUserHasPaymentMethod = createSelector(getWsData, (wsData) =>
  !isNilOrEmpty(wsData.user?.stripe?.payment_method) ? true : false
);

export const getUserLanguage = createSelector(
  getWsData,
  (wsData) => wsData.user?.language || USER_LANGUAGE.EN
);

export const getSystemMetas = createSelector(
  getWsData,
  (wsData) => wsData?.system_metas || []
);

export const getEnergyPercentage = createSelector(getWsData, (wsData) => {
  const walletLimitMax = wsData.user.wallet?.limit.max || 0;
  const userEnergy = wsData.user.wallet?.energy || 0;

  const today = Date.now();
  const expiryDate = wsData.user.wallet?.expiry_date || today;
  const isWalletExpired = isDateAfter(today, expiryDate);
  const isAllowManualTopup = wsData.user.wallet?.is_allow_manual_topup || false;

  let percent = (userEnergy / walletLimitMax) * 100;
  percent = percent > 100 ? 100 : percent;
  percent = percent < 0 ? 0 : percent;

  let threshold = wsData.user.wallet?.limit?.threshold || 0;
  threshold = (threshold / walletLimitMax) * 100;

  return {
    current: !isAllowManualTopup && isWalletExpired ? 0 : percent,
    threshold,
  };
});

export const getWalletEnergy = createSelector(getWsData, (wsData) => {
  const energy = wsData.user.wallet?.energy || 0;

  const today = Date.now();
  const expiryDate = wsData.user.wallet?.expiry_date || today;
  const isWalletExpired = isDateAfter(today, expiryDate);
  const isAllowManualTopup = wsData.user.wallet?.is_allow_manual_topup || false;

  return !isAllowManualTopup && isWalletExpired ? 0 : energy;
});

export const getWalletLimit = createSelector(
  getWsData,
  (wsData) => wsData.user.wallet?.limit || {}
);

export const getTopupConvertionRate = createSelector(
  getWsData,
  (wsData) => wsData.user.wallet?.topup_convertion_rate || {}
);

export const getWallet = createSelector(
  getWsData,
  (wsData) => wsData?.user?.wallet || {}
);

export const getPids = createSelector(
  getWsData,
  (wsData) => wsData?.pids || {}
);

export const getSelectedPidObj = createSelector(
  getWsData,
  (wsData) => wsData?.pids?.[wsData?.appData.selectedCharger] || {}
);

export const getChargers = createSelector(
  getWsData,
  (wsData) => wsData?.charger || {}
);

export const getCharger = createSelector(
  getWsData,
  (wsData) => wsData?.charger?.[wsData?.appData.selectedCharger] || {}
);

export const getChargerStatus = createSelector(
  getWsData,
  (wsData) =>
    wsData?.charger?.[wsData?.appData.selectedCharger]?.status || STATUS.OFFLINE
);

export const isAllowManualTopup = createSelector(
  getWsData,
  (wsData) => wsData?.user?.wallet?.allow_manual_topup || false
);

export const isWalletExpired = createSelector(getWsData, (wsData) => {
  const today = Date.now();
  const expiryDate = wsData.user.wallet?.expiry_date || today;
  return isDateAfter(today, expiryDate);
});

export const isServicePlanExpired = createSelector(getWsData, (wsData) => {
  const today = Date.now();
  const expiryDate = wsData.user.service_plan?.expiry_date || today;
  return isDateAfter(today, expiryDate);
});

export const isServicePlanExpireSoon = createSelector(getWsData, (wsData) => {
  const today = new Date();
  const expiryDate = wsData.user.service_plan?.expiry_date || today;
  const expiryBefore = wsData.user.service_plan?.expiry_notification_before;
  if (!expiryBefore) {
    return false;
  }

  const expiryBeforeDate = subDays(new Date(expiryDate), expiryBefore);

  return isDateAfter(today, expiryBeforeDate) || isToday(expiryBeforeDate);
});

export const getControllerInfo = createSelector(
  getWsData,
  (wsData) => wsData?.controllerInfo || {}
);

export const getSmbMcbStatusCount = createSelector(getWsData, (wsData) => {
  const controllerInfo = wsData?.controllerInfo || {};
  const count = Object.keys(controllerInfo).reduce(
    (acc, key) => {
      const smartMcbStatus = controllerInfo[key].pidStatus.map((pid) =>
        pid.smartMcbStatus?.toUpperCase()
      );

      smartMcbStatus.forEach((status) => {
        if (status === "ON") {
          acc.on += 1;
        } else if (status === "OFF") {
          acc.off += 1;
        } else {
          acc.unknown += 1;
        }
      });

      return acc;
    },
    { on: 0, off: 0, unknown: 0 }
  );
  return count;
});
