import AddCardIcon from "@mui/icons-material/AddCard";
import { LoadingButton } from "@mui/lab";
import { Alert, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { STYLES } from "../constants/styles";
import { WS_TYPE } from "../constants/ws";
import { setWsReduxData } from "../lib/redux/wsSlice";
import api from "../utils/api";

const StripSetup = () => {
  const [loading, setLoading] = useState(false);
  const dispatchNotification = useDispatch();
  const { t } = useTranslation();

  const handleGoToPayment = async () => {
    setLoading(true);
    try {
      const { redirect } = (await api().post("/users/payment/setup")).data.data;
      window.location.href = redirect;
    } catch (e) {
      console.log(e);
      dispatchNotification(
        setWsReduxData({
          type: WS_TYPE.NOTIFICATION,
          data: {
            notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
            open: true,
            severity: "error",
            message: e.data,
          },
        })
      );
    }
    setLoading(false);
  };

  return (
    <Stack rowGap={1}>
      <Alert severity="info">{t("page.paymentSetup.description")}</Alert>
      <LoadingButton
        {...{ loading }}
        variant="contained"
        fullWidth
        sx={{ backgroundColor: STYLES.PRIMARY_COLOR }}
        onClick={handleGoToPayment}
      >
        <AddCardIcon sx={{ fontSize: "3rem !important" }} />
      </LoadingButton>
    </Stack>
  );
};

export default StripSetup;
