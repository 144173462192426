import ReactSpeedometer from "react-d3-speedometer";

import { MAX_CURRENT } from "../constants/charger";
import { STYLES } from "../constants/styles";

const ChargerMeterPlaceHolder = () => {
  return (
    <ReactSpeedometer
      paddingVertical={20}
      maxValue={MAX_CURRENT}
      minValue={0}
      height={200}
      width={300}
      value={0}
      currentValueText={`0 A`}
      segments={5}
      startColor={STYLES.PRIMARY_COLOR_LIGHT}
      endColor={STYLES.PRIMARY_COLOR}
      segmentValueFormatter={(value) => `${parseFloat(value).toFixed(0)}A`}
    />
  );
};

export default ChargerMeterPlaceHolder;
