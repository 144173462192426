import { Divider, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  getControllerInfo,
  getSmbMcbStatusCount,
} from "../../lib/redux/wsSlice";
import { isNilOrEmpty } from "../../utils";

const ControllerInfo = () => {
  const { t } = useTranslation();
  const controllerInfo = useSelector((state) => getControllerInfo(state));
  const smbMcbStatusCount = useSelector((state) => getSmbMcbStatusCount(state));

  return (
    <Box display="flex" width="100%">
      <Box display="flex" flex="1 1 0px" justifyContent="center">
        <Box>
          <Typography variant="caption" style={{ color: "green" }}>
            {t("page.admin.mcbStatus.on")}
          </Typography>
          {!isNilOrEmpty(controllerInfo) ? (
            <Typography variant="h5" style={{ color: "green" }}>
              {smbMcbStatusCount.on}
            </Typography>
          ) : (
            <Typography variant="h5">
              <Skeleton variant="text" width="100px" />
            </Typography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" />
      <Box display="flex" flex="1 1 0px" justifyContent="center">
        <Box>
          <Typography variant="caption" style={{ color: "red" }}>
            {t("page.admin.mcbStatus.off")}
          </Typography>
          {!isNilOrEmpty(controllerInfo) ? (
            <Typography variant="h5" style={{ color: "red" }}>
              {smbMcbStatusCount.off}
            </Typography>
          ) : (
            <Typography variant="h5">
              <Skeleton variant="text" width="100px" />
            </Typography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" />
      <Box display="flex" flex="1 1 0px" justifyContent="center">
        <Box>
          <Typography variant="caption" style={{ color: "grey" }}>
            {t("page.admin.mcbStatus.unknown")}
          </Typography>
          {!isNilOrEmpty(controllerInfo) ? (
            <Typography variant="h5" style={{ color: "grey" }}>
              {smbMcbStatusCount.unknown}
            </Typography>
          ) : (
            <Typography variant="h5">
              <Skeleton variant="text" width="100px" />
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ControllerInfo;
