import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { STYLES } from "../constants/styles";
import { WS_TYPE } from "../constants/ws";
import useQuery from "../hooks/useQuery";
import {
  getUser,
  isServicePlanExpireSoon,
  isServicePlanExpired,
  setWsReduxData,
} from "../lib/redux/wsSlice";
import { isNilOrEmpty } from "../utils";
import api from "../utils/api";
import ConfirmationDialog from "./ConfirmationDialog";

const INIT_STATE = {
  renewing: false,
  confirmOpen: false,
  paymentMethod: {},
  loadingPaymentMethod: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setPaymentMethod":
      return {
        ...state,
        loadingPaymentMethod: false,
        paymentMethod: action.payload,
      };
    case "renewing":
      return { ...state, renewing: true };
    case "renewSuccess":
      return {
        ...state,
        renewing: false,
      };
    case "renewFailed":
      return {
        ...state,
        renewing: false,
      };
    case "confirm":
      return { ...state, confirmOpen: true, loadingPaymentMethod: true };
    case "confirmClose":
      return { ...state, confirmOpen: false, paymentMethod: {} };
    default:
      return state;
  }
};

const ServicePlanRenewButton = () => {
  const { t } = useTranslation();
  const servicePlanExpired = useSelector((state) =>
    isServicePlanExpired(state)
  );
  const servicePlanExpireSoon = useSelector((state) =>
    isServicePlanExpireSoon(state)
  );
  const dispatchNotification = useDispatch();
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const user = useSelector((state) => getUser(state));
  const query = useQuery();
  const sessionId = query.get("sessionId");

  useEffect(() => {
    let cancelRequest = false;
    (async () => {
      if (sessionId && !cancelRequest) {
        const paymentSession = (
          await api().get(`/payment/session/${sessionId}`)
        ).data;
        if (paymentSession.status_code === 1000) {
          dispatchNotification(
            setWsReduxData({
              type: WS_TYPE.NOTIFICATION,
              data: {
                notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
                open: true,
                severity: "success",
                message: t("page.account.e+care.renew.dialog.successMsg", {
                  amount: paymentSession.data.amount_total / 100,
                }),
              },
            })
          );
        }
      }
    })();
    return () => {
      cancelRequest = true;
    };
  }, [sessionId]);

  const handleRenewClick = async () => {
    dispatch({ type: "confirm" });
    const paymentMethod = (await api().get("/users/payment/get-payment-method"))
      .data;
    dispatch({ type: "setPaymentMethod", payload: paymentMethod.data });
  };

  const handleRenewConfirm = async (changeCard = false) => {
    if (!isNilOrEmpty(user.stripe?.payment_method) && !changeCard) {
      try {
        dispatch({ type: "renewing" });
        const response = (
          await api().post("/users/payment/renew-service-plan-quick")
        ).data;

        if (response.status_code !== 1000) {
          throw new Error(response.status_message);
        }

        const { amount } = response.data;

        dispatch({ type: "renewSuccess" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "success",
              message: t("page.account.e+care.renew.dialog.successMsg", {
                amount,
              }),
            },
          })
        );
      } catch (e) {
        console.log(e);
        dispatch({ type: "renewFailed" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "error",
              message: e.message,
            },
          })
        );
      }
    } else {
      try {
        const { redirect } = (
          await api().post("/users/payment/renew-service-plan")
        ).data.data;
        window.location.href = redirect;
      } catch (e) {
        console.log(e);
        dispatch({ type: "renewFailed" });
        dispatchNotification(
          setWsReduxData({
            type: WS_TYPE.NOTIFICATION,
            data: {
              notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
              open: true,
              severity: "error",
              message: e.data,
            },
          })
        );
      }
    }
  };

  if (!servicePlanExpired && !servicePlanExpireSoon) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        size="small"
        variant="contained"
        startIcon={<AutorenewIcon />}
        onClick={handleRenewClick}
        sx={{
          backgroundColor: STYLES.SECONDARY_COLOR,
          color: "white",
          filter: "drop-shadow(1px 1px 1px black)",
          borderRadius: 2,
        }}
      >
        {t("common.button.renew")}
      </Button>

      <ConfirmationDialog
        content={
          <Stack>
            {
              <Typography variant="h6">
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  rowGap={1}
                >
                  <Box>{t("page.account.e+care.renew.dialog.title")}</Box>
                  <Typography
                    component="div"
                    variant="body"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      border="1"
                      sx={{ color: STYLES.SECONDARY_COLOR }}
                    >
                      <Box>
                        {user.service_plan.expiry_in.value}{" "}
                        {t(
                          `page.account.e+care.renew.unit.${user.service_plan.expiry_in.unit}`
                        )}
                      </Box>
                      <Box>HKD${user.service_plan.price}</Box>
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            }
            {!isNilOrEmpty(state.paymentMethod) && (
              <TextField
                label={state.paymentMethod.brand.toUpperCase()}
                value={`\u{FF0E}\u{FF0E}\u{FF0E}\u{FF0E}${state.paymentMethod.last4}`}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => handleRenewConfirm(true)}
                    >
                      <Button>{t("common.button.change")}</Button>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                size="small"
                sx={{ marginTop: 5 }}
              />
            )}
          </Stack>
        }
        confirmAction={handleRenewConfirm}
        open={state.confirmOpen}
        loadingButton
        loadingButtonState={state.loadingPaymentMethod || state.renewing}
        handleClose={() => dispatch({ type: "confirmClose" })}
      />
    </>
  );
};

export default ServicePlanRenewButton;
