import { WS_TYPE } from "../constants/ws";
import { debug } from "../utils";
import Api from "../utils/api";

const PING_INTERVAL = 10 * 1000;
const PING_CHECK_INTERVAL = PING_INTERVAL * 1.5;
const DISCONNECT_RELOAD_TIMER = 3 * 1000;

let pingTimer;
let pingCheckTimer;
let lastPong = 0;

const _ws = () => {
  const api = Api();
  return {
    connect: (token, onMsgCallback, stateChangeCallback = {}) => {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_URL}?token=${token.trim()}`
      );

      const disconnect = () => {
        if (ws) {
          ws.close();
        }
        if (pingCheckTimer) {
          clearInterval(pingCheckTimer);
        }
        if (pingTimer) {
          clearInterval(pingTimer);
        }
      };

      ws.onopen = async () => {
        debug(`[websocket] connected`);

        await api.get("/mqtt/controller-info");

        pingTimer = setInterval(() => {
          if (ws && ws.readyState === 1) {
            ws.send(JSON.stringify({ type: WS_TYPE.PING }));
          }
        }, PING_INTERVAL);

        if (stateChangeCallback.onopen) {
          stateChangeCallback.onopen(ws);
        }
      };

      pingCheckTimer = setInterval(() => {
        debug(
          `[websocket] check alive every ${PING_CHECK_INTERVAL / 1000} second`
        );
        if (Date.now() - lastPong > PING_INTERVAL) {
          debug(
            `[websocket] disconnected, reload page in ${
              DISCONNECT_RELOAD_TIMER / 1000
            } second`
          );
          clearInterval(pingTimer);
          clearInterval(pingCheckTimer);

          ws.close();

          setTimeout(() => {
            window.location.reload();
          }, DISCONNECT_RELOAD_TIMER);
        }
      }, PING_CHECK_INTERVAL);

      ws.onclose = (e) => {
        debug(`[websocket] closed unexpectedly ${e.reason}`);
        if (stateChangeCallback.onclose) {
          stateChangeCallback.onclose(e.reason);
        }
      };

      ws.onmessage = (e) => {
        let data;
        try {
          data = JSON.parse(e.data);
        } catch (error) {
          data = e.data;
        }
        if (data === "pong") {
          lastPong = Date.now();
        } else {
          onMsgCallback(data);
        }
      };

      return { disconnect };
    },
  };
};

export default { connect: _ws().connect };
