import { Box, Typography } from "@mui/material";
import BatteryGauge from "react-battery-gauge";
import { useSelector } from "react-redux";

import { STYLES } from "../constants/styles";
import {
  getEnergyPercentage,
  getUser,
  getWalletEnergy,
  getWalletLimit,
} from "../lib/redux/wsSlice";
import { isNilOrEmpty, toFixed } from "../utils";

const EnergyMeter = () => {
  const user = useSelector((state) => getUser(state));
  const walletLimit = useSelector((state) => getWalletLimit(state));
  const energyPercentage = useSelector((state) => getEnergyPercentage(state));
  const walletEnergy = useSelector((state) => getWalletEnergy(state));

  if (isNilOrEmpty(user) || isNilOrEmpty(walletLimit)) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <BatteryGauge
          value={energyPercentage.current}
          size={200}
          customization={{
            batteryBody: {
              strokeWidth: 2,
              strokeColor: STYLES.PRIMARY_COLOR,
            },
            batteryCap: {
              strokeWidth: 2,
              strokeColor: STYLES.PRIMARY_COLOR,
            },
            batteryMeter: {
              lowBatteryValue: energyPercentage.threshold,
              noOfCells: 5,
            },
            readingText: {
              lightContrastColor: STYLES.PRIMARY_COLOR,
              style: {
                textShadow: "1px 1px 1px grey",
              },
            },
          }}
        />
      </Box>
      <Box alignSelf="center">
        <Box display="flex" alignItems="baseline" columnGap={1}>
          <Box display="flex" alignItems="baseline">
            <Typography
              variant="h5"
              {...(walletEnergy <= 0 && { style: { color: "red" } })}
            >
              {toFixed(walletEnergy, 0)}
            </Typography>
            <Typography
              variant="caption"
              {...(walletEnergy <= 0 && { style: { color: "red" } })}
            >
              .{walletEnergy.toFixed(3).split(".")[1]}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            style={{ color: walletEnergy <= 0 ? "red" : "grey" }}
          >
            kWh
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyMeter;
