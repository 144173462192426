import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import { WALLET_TYPE } from "../../constants/wallet";
import { getUserLanguage, getWallet } from "../../lib/redux/wsSlice";
import { format, toDate, toFixed } from "../../utils";
import api from "../../utils/api";
import Receipt from "./Receipt";
import ReportTotal from "./ReportTotal";

const INIT_STATE = {
  reportLoading: false,
  reportData: [],
  reportTotal: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setReport":
      return {
        ...state,
        reportData: action.payload,
        reportLoading: false,
      };
    case "setReportTotal":
      return {
        ...state,
        reportTotal: action.payload,
      };
    default:
      return state;
  }
};

const TypeIcon = ({ type }) => {
  const { t } = useTranslation();
  const style = { fontSize: "0.8rem", color: "grey" };

  switch (type) {
    case WALLET_TYPE.AUTO_TOPUP: {
      return (
        <Tooltip title={t("page.wallet.report.topupType.auto")}>
          <FlashAutoIcon style={style} />
        </Tooltip>
      );
    }
    case WALLET_TYPE.ADMIN_TOPUP: {
      return (
        <Tooltip title={t("page.wallet.report.topupType.admin")}>
          <SupervisorAccountIcon style={style} />
        </Tooltip>
      );
    }
    case WALLET_TYPE.QUICK_TOPUP: {
      return (
        <Tooltip title={t("page.wallet.report.topupType.storedCard")}>
          <CreditCardOffOutlinedIcon style={style} />
        </Tooltip>
      );
    }
    case WALLET_TYPE.TOPUP: {
      return (
        <Tooltip title={t("page.wallet.report.topupType.newCard")}>
          <AddCardOutlinedIcon style={style} />
        </Tooltip>
      );
    }
  }
};

const Day = ({ containerState, containerDispatch }) => {
  const wallet = useSelector((state) => getWallet(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const selectedDate = toDate(containerState.selected.date);

  let cancelRequest = false;
  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
    () => {
      cancelRequest = true;
      dispatch({ type: "reset" });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
  }, [wallet.expiry_date]);

  const loadReportData = async () => {
    const dateFrom = startOfDay(selectedDate);
    const dateTo = endOfDay(selectedDate);

    const response = (
      await api().get(`/users/wallet-logs`, {
        params: {
          date_from: dateFrom.toISOString(),
          date_to: dateTo.toISOString(),
        },
      })
    ).data;

    if (cancelRequest) return;

    const data = response.data;
    const totalEnergy = data.reduce(
      (acc, record) => acc + record.energy_changed,
      0
    );
    const totalMoney = data.reduce(
      (acc, record) =>
        acc +
        (record.payment_intents?.amount
          ? record.payment_intents.amount / 100
          : 0),
      0
    );

    dispatch({
      type: "setReportTotal",
      payload: { energy: totalEnergy, money: totalMoney },
    });
    dispatch({
      type: "setReport",
      payload: data.filter((d) => d.energy_changed > 0),
    });
  };

  return (
    <Box width="100%">
      <ReportTotal
        title={format(
          selectedDate,
          DATE_FORMAT[userLanguage].DATE_LONG,
          userLanguage
        )}
        total={state.reportTotal}
        back={() => containerDispatch({ type: "MONTH", payload: selectedDate })}
      />

      <Stack px={2} rowGap={2}>
        {state.reportData.map((data, i) => (
          <span key={data._id}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              sx={{
                ...(data.type === WALLET_TYPE.ADMIN_TOPUP && { opacity: 0.3 }),
              }}
            >
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography variant="caption" style={{ color: "grey" }}>
                  {state.reportData.length - i}.
                </Typography>

                <Box>
                  <Box display="flex" columnGap={1} alignItems="center">
                    <Typography
                      variant="h6"
                      style={{ color: STYLES.PRIMARY_COLOR }}
                    >
                      {format(
                        data.createdAt,
                        DATE_FORMAT[userLanguage].TIME12,
                        userLanguage
                      )}
                    </Typography>
                    {data.type !== WALLET_TYPE.ADMIN_TOPUP && (
                      <Receipt paymentIntentId={data.payment_intents._id} />
                    )}
                  </Box>

                  <Box display="flex" columnGap={1} alignItems="center">
                    <Box display="flex" alignItems="center" columnGap="0.1rem">
                      <Typography variant="caption" style={{ color: "grey" }}>
                        HKD $
                        {data.payment_intents?.amount
                          ? data.payment_intents.amount / 100
                          : 0}
                      </Typography>
                    </Box>
                    <Box>
                      <TypeIcon type={data.type} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box marginLeft="auto">
                <Typography
                  variant="h6"
                  component="span"
                  style={{ color: STYLES.PRIMARY_COLOR }}
                >
                  {toFixed(data.energy_changed)}
                </Typography>
                &nbsp;
                <Typography
                  variant="subtitle2"
                  component="span"
                  style={{ color: STYLES.PRIMARY_COLOR }}
                >
                  kWh
                </Typography>
              </Box>
            </Box>
            <Divider />
          </span>
        ))}
      </Stack>
    </Box>
  );
};

export default Day;
