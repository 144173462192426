export const STYLES = {
  BACKGROUND_COLOR: "white",
  PRIMARY_COLOR: "#1A468F",
  PRIMARY_COLOR_LIGHT: "rgb(128,229,237)",
  PRIMARY_TEXT_COLOR: "white",
  PRIMARY_TEXT_SHADOW_COLOR: "black",
  SECONDARY_COLOR: "#00AEBD",
  PID_STATUS_COLOR: {
    AVAILABLE: "#00AEBD",
    PREPARING: "#00AEBD",
    CHARGING: "#1A468F",
    SUSPENDEDEV: "lightblue",
    SUSPENDEDEVSE: "lightblue",
    FINISHING: "#FFC709",
    TRIPPED: "red",
    FAULTED: "red",
    DEFAULT: "lightgrey",
  },
};
