import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { STYLES } from "../../constants/styles";
import { secondsToDuration, toFixed } from "../../utils";

const FormattedDuration = ({ value, unit }) => {
  return (
    <Box display="flex" alignItems="baseline">
      <Typography variant="h6">{value}</Typography>
      <Typography variant="caption">{unit}</Typography>
    </Box>
  );
};

const ReportTotal = ({ title, total, back }) => {
  const { t } = useTranslation();
  const totalTime = useMemo(
    () => secondsToDuration((total?.time || 0) * 60).duration,
    [total.time]
  );

  return (
    <>
      {back && (
        <IconButton
          size="small"
          onClick={back}
          style={{
            position: "absolute",
            marginTop: 5,
            marginLeft: 5,
            color: STYLES.PRIMARY_COLOR,
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <Box
        pt={2}
        pb={1}
        pl={2}
        pr={2}
        mb={1}
        alignItems="center"
        backgroundColor="#f9f9f9"
        width="100%"
        display="flex"
        color={STYLES.PRIMARY_COLOR}
        justifyContent="center"
        flexWrap="wrap"
        flexDirection={"column"}
      >
        <Typography component="div" variant="h6">
          {title}
        </Typography>
        <Box display="flex" columnGap={3}>
          <Box>
            <Typography
              component="div"
              variant="caption"
              style={{ color: "grey" }}
            >
              {t("page.charger.report.chargedEnergy")}
            </Typography>
            <Box display="flex" alignItems="baseline" columnGap="0.1rem">
              <Typography variant="h6">{toFixed(total.energy)}</Typography>
              <Typography variant="caption">kWh</Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              component="div"
              variant="caption"
              style={{ color: "grey" }}
            >
              {t("page.charger.report.chargedTime")}
            </Typography>
            <>
              <Box display="flex" columnGap="0.2rem">
                {Object.values(totalTime).filter((t) => t > 0).length == 0 ? (
                  <FormattedDuration value={0} unit="s" />
                ) : (
                  <>
                    {totalTime.days > 0 && (
                      <FormattedDuration value={totalTime.days} unit="d" />
                    )}
                    {totalTime.hours > 0 && (
                      <FormattedDuration value={totalTime.hours} unit="h" />
                    )}
                    {totalTime.minutes > 0 && (
                      <FormattedDuration value={totalTime.minutes} unit="m" />
                    )}
                    {totalTime.seconds > 0 && (
                      <FormattedDuration value={totalTime.seconds} unit="s" />
                    )}
                  </>
                )}
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportTotal;
