import { Paper, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

// import { USER_TYPE } from "../constants/users";
import { getSystemMetas, getUser } from "../lib/redux/wsSlice";
import { isNilOrEmpty } from "../utils";

const HomePaper = (props) => {
  const user = useSelector((state) => getUser(state));
  // const userType = useSelector((state) => getUserType(state));
  // const charger = useSelector((state) => getCharger(state));
  const systemMetas = useSelector((state) => getSystemMetas(state));

  // fixme: allow user to access without charger fully configured
  if (
    isNilOrEmpty(user) ||
    // (userType === USER_TYPE.USER && isNilOrEmpty(charger)) ||
    isNilOrEmpty(systemMetas)
  ) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        width="100%"
        height={"60px"}
        sx={{ marginBottom: 1 }}
      />
    );
  }

  const sx = {
    display: "flex",
    justifyContent: "center",
    borderRadius: 2,
    ...(!props.nopaper && { marginBottom: 1 }),
    ...(!props.nopaper && { padding: 2 }),
  };

  const style = {
    ...props.style,
  };

  if (props.nopaper) {
    return (
      <Box width="100%" sx={sx} style={style}>
        {props.children}
      </Box>
    );
  } else {
    return (
      <Paper width="100%" sx={sx} style={style}>
        {props.children}
      </Paper>
    );
  }
};

export default HomePaper;
