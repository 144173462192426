import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { STYLES } from "../../constants/styles";
import { toFixed } from "../../utils";

const ReportTooltip = ({ payload, titleFormatter }) => {
  const { t } = useTranslation();
  payload = payload.payload?.[0]?.payload || null;
  if (!payload) return null;

  if (parseFloat(payload.kWh) === 0 || parseFloat(payload.kWh) === 0)
    return null;

  const title = titleFormatter(payload);

  return (
    <Paper sx={{ padding: 2, color: STYLES.PRIMARY_COLOR }} elevation={6}>
      <Typography component="div" variant="h5">
        {title}
      </Typography>
      <Box display="flex" columnGap={2}>
        <Box>
          <Typography
            component="div"
            variant="caption"
            style={{ color: "grey" }}
          >
            {t("page.charger.report.chargedEnergy")}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography variant="subtitle">{payload.kWh}</Typography>
            <Typography variant="caption">kWh</Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            component="div"
            variant="caption"
            style={{ color: "grey" }}
          >
            {t("page.charger.report.chargedTime")}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography variant="subtitle">
              {toFixed(payload.mintues, 2)}
            </Typography>
            <Typography variant="caption">m</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ReportTooltip;
