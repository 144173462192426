import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import EnergyMeter from "../components/EnergyMeter";
import HomeLayout from "../components/HomeLayout";
import HomePaper from "../components/HomePaper";
import TopupButton from "../components/TopupButton";
import WalletLogReportContainer from "../components/WalletLogReport/WalletLogreportContainer";
import { DATE_FORMAT } from "../constants/system";
import { USER_TYPE } from "../constants/users";
import { useAuthStatus } from "../hooks/useAuthStatus";
import {
  getUser,
  getUserLanguage,
  getUserType,
  getWalletEnergy,
  isWalletExpired,
} from "../lib/redux/wsSlice";
import { format } from "../utils";

const Wallet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoadingAuth, isAuthorized, userId, token } = useAuthStatus();

  const user = useSelector((state) => getUser(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const userType = useSelector((state) => getUserType(state));
  const walletExpired = useSelector((state) => isWalletExpired(state));
  const walletEnergy = useSelector((state) => getWalletEnergy(state));

  useEffect(() => {
    let cancelRequest = false;
    (async () => {
      if (isLoadingAuth || cancelRequest) {
        return;
      } else if (!isAuthorized) {
        navigate("/");
      }
    })();

    return () => {
      cancelRequest = true;
    };
  }, [isLoadingAuth, isAuthorized]);

  if (isLoadingAuth) {
    return;
  }

  return (
    <HomeLayout userId={userId} token={token}>
      {userType === USER_TYPE.USER && (
        <>
          {walletEnergy > 0 && (
            <HomePaper style={{ color: walletExpired ? "red" : "blue" }}>
              <Typography variant="body1">
                {t("page.wallet.expiry")}{" "}
                {format(
                  user.wallet?.expiry_date,
                  DATE_FORMAT[userLanguage].DATE
                )}
              </Typography>
            </HomePaper>
          )}

          <HomePaper>
            <EnergyMeter />
          </HomePaper>

          <HomePaper nopaper style={{ padding: 0, marginBottom: 12 }}>
            <TopupButton />
          </HomePaper>

          <HomePaper
            style={{
              padding: 0,
              paddingBottom: 16,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <WalletLogReportContainer />
          </HomePaper>
        </>
      )}
    </HomeLayout>
  );
};

export default Wallet;
