import { Container } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";

import Account from "../pages/Account";
import Home from "../pages/Home";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import Wallet from "../pages/Wallet";

const AppContainer = () => {
  return (
    <Container fixed disableGutters maxWidth="xs">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/wallet" element={<Wallet />} />
        <Route path="/home/account" element={<Account />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Container>
  );
};

export default AppContainer;
