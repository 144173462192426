import { Alert, LoadingButton } from "@mui/lab";
import { Divider, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import { getUserLanguage } from "../../lib/redux/wsSlice";
import { format } from "../../utils";
import api from "../../utils/api";
import ConfirmationDialog from "../ConfirmationDialog";
import HomePaper from "../HomePaper";
import ControllerInfo from "./ControllerInfo";

const AdminHome = () => {
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const userLanguage = useSelector((state) => getUserLanguage(state));

  const [logs, setLogs] = useState();

  useEffect(() => {
    (async () => {
      await reloadLogs();
    })();
  }, []);

  const reloadLogs = async () => {
    const logs = (await api().get(`/charger/carpark/turn-off`)).data;
    setLogs(logs.data);
  };

  const handleConfirm = async () => {
    setOpenConfirm(false);
    setLoading(true);
    await api().post(`/charger/carpark/turn-off`);
    setLoading(false);
    await reloadLogs();

    // trigger controllerInfo in 10 sec instead of waiting 1 min)
    setTimeout(async () => {
      await api().get("/mqtt/controller-info");
    }, 10 * 1000);
  };

  return (
    <>
      <HomePaper>
        <ControllerInfo />
      </HomePaper>

      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          sx={{
            borderRadius: 100,
            width: 200,
            height: 200,
            fontSize: 20,
            border: "10px solid pink",
          }}
          onClick={() => setOpenConfirm(true)}
        >
          {t("page.admin.emergency.button")}
        </LoadingButton>
        <ConfirmationDialog
          content={
            <Box display="flex" flexDirection="column" rowGap={1}>
              <Typography variant="h6">
                {t("page.admin.emergency.confirm.title")}
              </Typography>
              <Alert severity="warning">
                {t("page.admin.emergency.confirm.message")}
              </Alert>
            </Box>
          }
          open={openConfirm}
          confirmAction={handleConfirm}
          handleClose={() => setOpenConfirm(false)}
        />
      </Box>
      {logs && logs.length > 0 && (
        <HomePaper>
          <Stack px={2} rowGap={2} width="100%">
            {logs.map((data, i) => (
              <span key={data._id}>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  columnGap={1}
                >
                  <Typography variant="caption" style={{ color: "grey" }}>
                    {logs.length - i}.
                  </Typography>

                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        style={{ color: STYLES.PRIMARY_COLOR }}
                      >
                        {format(
                          data.createdAt,
                          DATE_FORMAT[userLanguage].DATETIME,
                          userLanguage
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>{data.user.name}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </span>
            ))}
          </Stack>
        </HomePaper>
      )}
    </>
  );
};

export default AdminHome;
