import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { WS_TYPE } from "../constants/ws";
import { setWsReduxData } from "../lib/redux/wsSlice";
import api from "../utils/api";

const ChangePaymentMethodButton = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const dispatchNotification = useDispatch();
  const { t } = useTranslation();

  const handleGoToPayment = async () => {
    setLoading(true);
    try {
      const { redirect } = (await api().post("/users/payment/setup")).data.data;
      window.location.href = redirect;
    } catch (e) {
      console.log(e);
      dispatchNotification(
        setWsReduxData({
          type: WS_TYPE.NOTIFICATION,
          data: {
            notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
            open: true,
            severity: "error",
            message: e.data,
          },
        })
      );
    }
    setLoading(false);
  };

  return (
    <LoadingButton
      {...{ disabled }}
      {...{ loading }}
      onClick={handleGoToPayment}
    >
      {t("page.account.wallet.changeCard")}
    </LoadingButton>
  );
};

export default ChangePaymentMethodButton;
