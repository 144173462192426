import { useSelector } from "react-redux";

import ChargerMeterContainer from "../../components/ChargerMeterContainer";
import ChargingData from "../../components/ChargingData";
import ChargingReportContainer from "../../components/ChargingReport/ChargingReportContainer";
import HomePaper from "../../components/HomePaper";
import { STATUS } from "../../constants/charger";
import { getCharger } from "../../lib/redux/wsSlice";

const UserHome = () => {
  const charger = useSelector((state) => getCharger(state));

  return (
    <>
      {[STATUS.CHARGING, STATUS.SUSPENDEDEV].includes(charger.status) && (
        <HomePaper>
          <ChargingData />
        </HomePaper>
      )}

      <HomePaper>
        <ChargerMeterContainer />
      </HomePaper>

      <HomePaper
        style={{
          padding: 0,
          paddingBottom: 16,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <ChargingReportContainer />
      </HomePaper>
    </>
  );
};

export default UserHome;
