import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import UndoIcon from "@mui/icons-material/Undo";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  InputAdornment,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { PassageUser } from "@passageidentity/passage-elements/passage-user";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { GoSignOut } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChangePaymentMethodButton from "../components/ChangePaymentMethodButton";
import HomeLayout from "../components/HomeLayout";
import HomePaper from "../components/HomePaper";
import ServicePlanRenewButton from "../components/ServicePlanRenewButton";
import { STYLES } from "../constants/styles";
import { DATE_FORMAT } from "../constants/system";
import { USER_LANGUAGE, USER_TYPE } from "../constants/users";
import { WS_TYPE } from "../constants/ws";
import { useAuthStatus } from "../hooks/useAuthStatus";
import {
  getUser,
  getUserLanguage,
  getUserType,
  isAllowManualTopup,
  isServicePlanExpired,
  isWalletExpired,
  setWsReduxData,
} from "../lib/redux/wsSlice";
import { format, isNilOrEmpty } from "../utils";
import api from "../utils/api";

const MyTextField = ({ edit, label, value, ...props }) => {
  const _props = { ...props };
  delete _props.InputProps;

  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      variant={edit ? "outlined" : "standard"}
      InputLabelProps={{ shrink: true }}
      InputProps={{ readOnly: !edit, ...props.InputProps }}
      {..._props}
    />
  );
};

const INIT_STATE = {
  data: {
    name: "",
    email: "",
    phone: "",
    language: USER_LANGUAGE.EN,
    wallet: { auto_topup: false },
    service_plan: { auto_renew: false },
  },
  edit: false,
  saving: false,
  error: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return { ...INIT_STATE };
    case "SET_FORM":
      return { ...state, data: action.payload };
    case "SET_EDIT":
      return { ...state, edit: true };
    case "CANCEL_EDIT":
      return { ...state, edit: false, data: action.payload };
    case "SET_SAVE":
      return { ...state, saving: true, error: false };
    case "SET_SAVE_ERROR":
      return { ...state, saving: false, error: action.payload };
    case "SET_SAVE_SUCCESS":
      return { ...state, saving: false, error: false, edit: false };
    default:
      return state;
  }
};

const Account = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoadingAuth, isAuthorized, userId, token } = useAuthStatus();
  const user = useSelector((state) => getUser(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const userType = useSelector((state) => getUserType(state));
  const walletExpired = useSelector((state) => isWalletExpired(state));
  const allowManualTopup = useSelector((state) => isAllowManualTopup(state));
  const servicePlanExpired = useSelector((state) =>
    isServicePlanExpired(state)
  );
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const dispatchNotification = useDispatch();

  useEffect(() => {
    (async () => {
      if (isLoadingAuth) {
        return;
      } else if (!isAuthorized) {
        navigate("/");
      }
    })();

    if (!isNilOrEmpty(user)) {
      dispatch({
        type: "CANCEL_EDIT",
        payload: {
          _id: user._id,
          email: user.email,
          name: user.name,
          phone: user.phone,
          language: user.language,
          ...(userType === USER_TYPE.USER && {
            wallet: { auto_topup: user.wallet.auto_topup },
          }),
          ...(userType === USER_TYPE.USER && {
            service_plan: { auto_renew: user.service_plan.auto_renew },
          }),
        },
      });
    }
  }, [isLoadingAuth, isAuthorized, user]);

  useEffect(() => {
    if (state.error) {
      dispatchNotification(
        setWsReduxData({
          type: WS_TYPE.NOTIFICATION,
          data: {
            notificationType: WS_TYPE.NOTIFICATION_TYPE.SNACKBAR,
            open: true,
            severity: "error",
            message: state.error,
          },
        })
      );
    }
  }, [state.error]);

  const handleSave = async () => {
    dispatch({ type: "SET_SAVE" });
    try {
      const res = (await api().patch(`/users`, state.data)).data;
      if (res.status_code === 1000) {
        dispatch({ type: "SET_SAVE_SUCCESS" });
      } else {
        dispatch({ type: "SET_SAVE_ERROR", payload: res.status_message });
      }
    } catch (e) {
      dispatch({ type: "SET_SAVE_ERROR", payload: e.message });
      console.log(e);
    }
  };

  if (isLoadingAuth) {
    return;
  }

  return (
    <HomeLayout userId={userId} token={token}>
      <HomePaper>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          width="100%"
          display="flex"
          flexDirection="column"
          rowGap={1}
        >
          <MyTextField
            label={t("page.account.name")}
            value={state.data.name}
            onChange={(e) =>
              dispatch({
                type: "SET_FORM",
                payload: { ...state.data, name: e.target.value },
              })
            }
            edit={false}
          />

          <MyTextField
            label={t("page.account.email")}
            value={state.data.email}
            onChange={(e) =>
              dispatch({
                type: "SET_FORM",
                payload: { ...state.data, email: e.target.value },
              })
            }
            edit={state.edit}
          />

          <MyTextField
            label={t("page.account.phone")}
            value={state.data.phone}
            onChange={(e) =>
              dispatch({
                type: "SET_FORM",
                payload: { ...state.data, phone: e.target.value },
              })
            }
            inputProps={{ maxLength: 8 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+852</InputAdornment>
              ),
            }}
            edit={state.edit}
          />

          <ToggleButtonGroup
            value={state.data.language}
            exclusive
            size="small"
            sx={{ width: "100%" }}
            disabled={!state.edit}
            onChange={(e) =>
              dispatch({
                type: "SET_FORM",
                payload: { ...state.data, language: e.target.value },
              })
            }
          >
            <ToggleButton value={USER_LANGUAGE.EN} sx={{ width: "100%" }}>
              {t("page.account.language.en")}
            </ToggleButton>
            <ToggleButton value={USER_LANGUAGE.ZH} sx={{ width: "100%" }}>
              {t("page.account.language.zh")}
            </ToggleButton>
          </ToggleButtonGroup>

          {userType === USER_TYPE.USER && (
            <>
              <fieldset style={{ borderRadius: 10, borderColor: "white" }}>
                <legend>
                  <Typography variant="subtitle2">
                    {t("page.account.wallet.legend")}
                  </Typography>
                </legend>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" columnGap={1}>
                    <Typography variant="subtitle2">
                      {t("page.account.wallet.autoTopup")}
                    </Typography>

                    {allowManualTopup ? (
                      <Switch
                        disabled={!state.edit}
                        color="primary"
                        checked={state.data.wallet.auto_topup}
                        onChange={(e) =>
                          dispatch({
                            type: "SET_FORM",
                            payload: {
                              ...state.data,
                              wallet: { auto_topup: e.target.checked },
                            },
                          })
                        }
                      />
                    ) : (
                      <ChangePaymentMethodButton disabled={!state.edit} />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      style={{ color: walletExpired ? "red" : "grey" }}
                    >
                      {t("page.account.expiry")}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ color: walletExpired ? "red" : "" }}
                    >
                      {format(
                        user.wallet?.expiry_date || Date.now(),
                        DATE_FORMAT[userLanguage].DATE
                      )}
                    </Typography>
                  </Box>
                </Box>
              </fieldset>
              <fieldset style={{ borderRadius: 10, borderColor: "white" }}>
                <legend>
                  <Typography variant="subtitle2">
                    {t("page.account.e+care.name")}
                  </Typography>
                </legend>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">
                      {t("page.account.e+care.autoRenew")}
                    </Typography>
                    <Switch
                      disabled={!state.edit}
                      color="primary"
                      checked={state.data.service_plan?.auto_renew || false}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_FORM",
                          payload: {
                            ...state.data,
                            service_plan: { auto_renew: e.target.checked },
                          },
                        })
                      }
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      style={{ color: servicePlanExpired ? "red" : "grey" }}
                    >
                      {t("page.account.expiry")}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ color: servicePlanExpired ? "red" : "" }}
                    >
                      {format(
                        user.service_plan?.expiry_date || Date.now(),
                        DATE_FORMAT[userLanguage].DATE
                      )}
                    </Typography>
                    <ServicePlanRenewButton />
                  </Box>
                </Box>
              </fieldset>
            </>
          )}

          {state.edit ? (
            <Box display="flex" columnGap={1} width="100%">
              <LoadingButton
                loading={state.saving}
                onClick={handleSave}
                fullWidth
                startIcon={<SaveAltIcon />}
                variant="contained"
                style={{ backgroundColor: STYLES.PRIMARY_COLOR }}
              >
                {t("common.button.save")}
              </LoadingButton>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => dispatch({ type: "CANCEL_EDIT", payload: user })}
                startIcon={<UndoIcon />}
                disabled={state.saving}
              >
                {t("common.button.cancel")}
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                dispatch({ type: "SET_EDIT" });
              }}
              startIcon={<EditIcon />}
              sx={{ backgroundColor: STYLES.PRIMARY_COLOR }}
            >
              {t("common.button.edit")}
            </Button>
          )}
        </Box>
      </HomePaper>

      <HomePaper nopaper style={{ marginTop: 20 }}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          color="error"
          onClick={async () => {
            const user = new PassageUser();
            await user.signOut();
            navigate("/");
          }}
          startIcon={<GoSignOut />}
        >
          {t("common.button.logout")}
        </Button>
      </HomePaper>

      <HomePaper nopaper style={{ marginTop: 20 }}>
        <Typography variant="body2" style={{ color: "grey" }}>
          {t("page.account.version")}: {process.env.REACT_APP_VERSION}
        </Typography>
      </HomePaper>
    </HomeLayout>
  );
};

export default Account;
