import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Tab, Tabs } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiTachometer } from "react-icons/bi";
import { FaWallet } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { STYLES } from "../constants/styles";
import { getAppSettings } from "../lib/redux/wsSlice";

const MyTab = ({ pathname, ...props }) => {
  const { value, label, icon } = props;
  const _label = pathname === value ? label : "";
  return (
    <Tab disableRipple {...props} icon={icon} label={_label} value={value} />
  );
};

const Header = () => {
  const { t } = useTranslation();
  const appSettings = useSelector((state) => getAppSettings(state));
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <>
      <Box
        maxWidth="inherit"
        width="inherit"
        height={`${appSettings.headerHeight}px`}
        backgroundColor={STYLES.PRIMARY_COLOR}
        position="fixed"
        top={0}
        zIndex={zIndex.appBar}
        p={1}
        sx={{
          backgroundImage: "url(/logo.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          padding: 0,
        }}
      >
        <Tabs
          value={location.pathname}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          sx={{
            "& button": {
              padding: 0,
              height: `${appSettings.headerHeight}px`,
              minHeight: 0,
              color: STYLES.PRIMARY_TEXT_COLOR,
              paddingLeft: 2,
              paddingRight: 2,
            },
            "& button.Mui-selected": {
              color: STYLES.PRIMARY_TEXT_COLOR,
            },
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-end",
            },
          }}
          centered
        >
          <MyTab
            pathname={location.pathname}
            label={t("header.charger")}
            value="/home"
            icon={<BiTachometer size={25} />}
          />
          <MyTab
            pathname={location.pathname}
            label={t("header.wallet")}
            value="/home/wallet"
            icon={<FaWallet size={25} />}
          />
          <MyTab
            pathname={location.pathname}
            label={t("header.account")}
            value="/home/account"
            icon={<AccountCircleIcon size={25} />}
          />
        </Tabs>
      </Box>
      <Box
        height={`${appSettings.headerHeight}px`}
        sx={{ marginBottom: 1 }}
      ></Box>
    </>
  );
};

export default Header;
