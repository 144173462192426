export const WS_TYPE = {
  USER: "user",
  CHARGER: "charger",
  PING: "ping",
  SYSTEM_METAS: "system-metas",
  PIDS: "pids",
  NOTIFICATION: "notification",
  CONTROLLER_INFO: "controller-info",
  NOTIFICATION_TYPE: {
    SNACKBAR: "snackbar",
  },
};

export const WS_ACTION = {
  REFRESH_USER: "refresh_user",
  CHARGER_METER_VALUES: "charger_meter_values",
  CHARGER_STATUS: "charger_status",
};

export const WS_CONNECTION_TYPE = {
  USER: "user",
  ADMIN: "admin",
};
