import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { STYLES } from "../../constants/styles";
import { toFixed } from "../../utils";

const ReportTotal = ({ title, total, back }) => {
  const { t } = useTranslation();
  return (
    <>
      {back && (
        <IconButton
          size="small"
          onClick={back}
          style={{
            position: "absolute",
            marginTop: 5,
            marginLeft: 5,
            color: STYLES.PRIMARY_COLOR,
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <Box
        pt={2}
        pb={1}
        pl={2}
        pr={2}
        mb={1}
        alignItems="center"
        backgroundColor="#f9f9f9"
        width="100%"
        display="flex"
        color={STYLES.PRIMARY_COLOR}
        justifyContent="center"
        flexWrap="wrap"
        flexDirection={"column"}
      >
        <Typography component="div" variant="h6">
          {title}
        </Typography>
        <Box display="flex" columnGap={3}>
          <Box>
            <Typography
              component="div"
              variant="caption"
              style={{ color: "grey" }}
            >
              {t("page.wallet.report.topupedUpEnergy")}
            </Typography>
            <Box display="flex" alignItems="baseline" columnGap="0.1rem">
              <Typography variant="h6">{toFixed(total?.energy)}</Typography>
              <Typography variant="caption">kWh</Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              component="div"
              variant="caption"
              style={{ color: "grey" }}
            >
              {t("page.wallet.report.topupedUpAmount")}
            </Typography>
            <Box display="flex" alignItems="baseline" columnGap="0.1rem">
              <Typography variant="h6">{total.money || 0}</Typography>
              <Typography variant="caption">HKD</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportTotal;
