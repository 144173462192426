import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { CircularProgress, Tooltip } from "@mui/material";
import { useState } from "react";

import { STYLES } from "../../constants/styles";
import api from "../../utils/api";

const Receipt = ({ paymentIntentId, multi }) => {
  const [downloading, setDownloading] = useState(false);
  const downloadReceipt = async () => {
    setDownloading(true);
    const url = multi
      ? `/users/payment/receipt/multi`
      : `/users/payment/receipt/${paymentIntentId}`;
    const file = await api().get(url, {
      responseType: "blob",
      ...(multi && { params: multi }),
    });
    const temp = window.URL.createObjectURL(
      new Blob([file.data], { type: "application/pdf" })
    );
    window.open(temp);
    setDownloading(false);
  };

  return downloading ? (
    <CircularProgress size={20} />
  ) : (
    <Tooltip title="Download Receipt">
      <ReceiptLongOutlinedIcon
        onClick={downloadReceipt}
        style={{
          color: STYLES.PRIMARY_COLOR,
          cursor: "pointer",
        }}
      />
    </Tooltip>
  );
};

export default Receipt;
