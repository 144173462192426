import { Box } from "@mui/material";

import { STYLES } from "../constants/styles";

const Footer = () => {
  return (
    <Box
      sx={{
        bottom: 0,
        width: "inherit",
        maxWidth: "inherit",
        position: "fixed",
        height: 10,
        backgroundColor: STYLES.PRIMARY_COLOR,
      }}
      elevation={3}
    ></Box>
  );
};

export default Footer;
