import { LoadingButton } from "@mui/lab";
import { Box, LinearProgress, Paper, Stack } from "@mui/material";
import * as R from "ramda";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { STYLES } from "../constants/styles";
import { getSystemMetas, getUserLanguage } from "../lib/redux/wsSlice";
import api from "../utils/api";

const TermsAndConditions = ({ needAction }) => {
  const { t } = useTranslation();

  const systemMetas = useSelector((state) => getSystemMetas(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const [loading, setLoading] = useState(false);

  const handleAgreeTerms = async () => {
    setLoading(true);
    await api().patch(`/users`, { agree_terms: true });
  };

  if (!systemMetas) return <LinearProgress />;

  const terms = R.find(R.propEq("key", "terms"), systemMetas);

  return (
    <Stack rowGap={3}>
      <Paper sx={{ overflow: "scroll", height: "100%", margin: "5px" }}>
        <Box
          margin={3}
          dangerouslySetInnerHTML={{
            __html: terms?.value[userLanguage],
          }}
        />
      </Paper>
      {needAction && (
        <LoadingButton
          {...{ loading }}
          onClick={handleAgreeTerms}
          variant="contained"
          justifyContent="center"
          sx={{ backgroundColor: STYLES.PRIMARY_COLOR }}
        >
          {t("common.button.agree")}
        </LoadingButton>
      )}
    </Stack>
  );
};

export default TermsAndConditions;
