import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { STATUS } from "../constants/charger";
import { STYLES } from "../constants/styles";
import { getCharger } from "../lib/redux/wsSlice";
import { secondsToDuration } from "../utils";

const ChargingData = () => {
  const { t } = useTranslation();
  const charger = useSelector((state) => getCharger(state));

  const sx = {
    fontSize: "70px",
    color: STYLES.PRIMARY_COLOR_LIGHT,
  };
  switch (charger.status) {
    case STATUS.CHARGING: {
      sx.filter = "hue-rotate(45deg)";
      break;
    }
    case STATUS.SUSPENDEDEV: {
      sx.filter = "hue-rotate(70deg)";
      break;
    }
  }

  const chargedDuration = charger?.message?.chargedTime
    ? secondsToDuration(charger.message.chargedTime).formatted
    : 0;

  return (
    <Box
      display="flex"
      width="100%"
      sx={{ color: STYLES.PID_STATUS_COLOR[charger.status.toUpperCase()] }}
    >
      <Box display="flex" flex="1 1 0px" justifyContent="center">
        <Box>
          <Typography variant="caption" style={{ color: "grey" }}>
            {t("page.charger.report.chargedEnergy")}
          </Typography>
          {charger?.message?.meterValue ? (
            <Box display="flex" alignItems="baseline" columnGap="0.1rem">
              <Typography variant="h5">{charger.message.meterValue}</Typography>
              <Typography variant="subtitle2">kWh</Typography>
            </Box>
          ) : (
            <Typography variant="h5">
              <Skeleton variant="text" width="100px" />
            </Typography>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" />
      <Box display="flex" flex="1 1 0px" justifyContent="center">
        <Box>
          <Typography variant="caption" style={{ color: "grey" }}>
            {t("page.charger.report.chargedTime")}
          </Typography>
          <Typography variant="h5">
            {chargedDuration || <Skeleton variant="text" width="100px" />}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChargingData;
