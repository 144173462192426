export const STATUS = {
  AVAILABLE: "Available",
  PREPARING: "Preparing",
  SUSPENDEDEVSE: "SuspendedEVSE",
  SUSPENDEDEV: "SuspendedEV",
  CHARGING: "Charging",
  FINISHING: "Finishing",
  RESERVED: "Reserved",
  UNAVAILABLE: "Unavailable",
  FAULTED: "Faulted",
  OFFLINE: "Offline",
  TRIPPED: "Tripped",
  LOCKED: "Locked",
};

export const PID_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const MAX_CURRENT = 32;
