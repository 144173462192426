import { USER_LANGUAGE } from "./users";

export const TIME_ZONE = "Asia/Hong_Kong";

export const DATE_FORMAT = {
  [USER_LANGUAGE.EN]: {
    DATE: "yyyy-MM-dd",
    DATE_LONG: "do MMM, yyyy",
    TIME: "HH:mm:ss",
    TIME12: "K:mm:ssaaa",
    DATETIME: "yyyy-MM-dd HH:mm:ss",
    MONTH: "MMMM",
    MONTH_YEAR: "MMMM yyyy",
    MONTH_YEAR_SHORT: "MMM, yy",
  },
  [USER_LANGUAGE.ZH]: {
    DATE: "yyyy-MM-dd",
    DATE_LONG: "MMMdo, yyyy",
    TIME: "HH:mm:ss",
    TIME12: "K:mm:ssaaa",
    DATETIME: "yyyy-MM-dd HH:mm:ss",
    MONTH: "MMMM",
    MONTH_YEAR: "MMMM yyyy",
    MONTH_YEAR_SHORT: "MMM, yy",
  },
};
