import { Box } from "@mui/system";
import { getMonth, startOfDay, subMonths } from "date-fns";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { STYLES } from "../../constants/styles";
import { DATE_FORMAT } from "../../constants/system";
import { getUserLanguage, getWallet } from "../../lib/redux/wsSlice";
import { format, toDate, toFixed } from "../../utils";
import api from "../../utils/api";
import ReportTooltip from "./ReportTooltip";
import ReportTotal from "./ReportTotal";

const INIT_STATE = {
  reportLoading: true,
  reportData: [],
  reportTotal: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return { ...INIT_STATE };
    case "setReport":
      return {
        ...state,
        reportData: action.payload,
        reportLoading: false,
      };
    case "setReportTotal":
      return {
        ...state,
        reportTotal: action.payload,
      };
    default:
      return state;
  }
};

const Last12Month = ({ containerState, containerDispatch }) => {
  const { t } = useTranslation();
  const wallet = useSelector((state) => getWallet(state));
  const userLanguage = useSelector((state) => getUserLanguage(state));
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  let cancelRequest = false;
  useEffect(() => {
    (async () => {
      await loadReportData();
    })();

    () => {
      cancelRequest = true;
      dispatch({ type: "reset" });
    };
  }, []);

  useEffect(() => {
    (async () => {
      await loadReportData();
    })();
  }, [wallet.expiry_date]);

  const loadReportData = async () => {
    const response = (
      await api().get(`/users/wallet-logs`, {
        params: {
          date_from: startOfDay(containerState.dateRange.from).toISOString(),
        },
      })
    ).data;

    if (cancelRequest) return;

    const data = [];
    let totalEnergy = 0;
    let totalMoney = 0;
    for (let i = 0; i < 12; i++) {
      const subMonth = subMonths(containerState.today, 11 - i);
      const month = subMonth.getMonth();

      let energy = 0;
      let money = 0;
      response.data.forEach((record) => {
        const recordMonth = getMonth(toDate(record.createdAt));
        if (recordMonth === month) {
          money += record.payment_intents
            ? record.payment_intents.amount / 100
            : 0;
          energy += record.energy_changed;
          if (isNaN(energy)) {
            console.log(record);
          }
        }
      });
      totalEnergy += energy;
      totalMoney += money;
      data.push({
        name: month + 1,
        month: subMonth,
        energy: parseFloat(toFixed(energy)),
        money,
      });
    }

    dispatch({
      type: "setReportTotal",
      payload: { energy: totalEnergy, money: totalMoney },
    });
    dispatch({ type: "setReport", payload: data });
  };

  const handleClick = (data) => {
    try {
      data = data.activePayload[0].payload;
      if (parseFloat(data.energy) > 0) {
        containerDispatch({ type: "MONTH", payload: data.month });
      }
    } catch (e) {}
  };

  return (
    <Box width="100%">
      <ReportTotal
        title={t("page.wallet.report.yearTitle")}
        total={state.reportTotal}
      />

      <Box width={"100%"} height={300}>
        <ResponsiveContainer>
          <BarChart
            data={state.reportData}
            margin={{ right: 10, left: 0 }}
            fontFamily={"ProductSans, NotoSans"}
            fontSize="12px"
            onClick={handleClick}
          >
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis dataKey="name" stroke={STYLES.PRIMARY_COLOR} />
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke={STYLES.PRIMARY_COLOR}
              padding={{ top: 10 }}
              label={{
                value: "kWh",
                position: "insideTop",
                offset: 25,
                fill: STYLES.PRIMARY_COLOR,
              }}
            />
            <Tooltip
              content={(payload) => (
                <ReportTooltip
                  payload={payload}
                  titleFormatter={(payload) =>
                    format(
                      payload.month,
                      DATE_FORMAT[userLanguage].MONTH,
                      userLanguage
                    )
                  }
                />
              )}
            />
            <Bar
              yAxisId="left"
              dataKey="energy"
              fill={STYLES.PRIMARY_COLOR}
              radius={[4, 4, 0, 0]}
              maxBarSize={8}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default Last12Month;
